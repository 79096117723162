import DataLayerService from "../../../services/DataLayerService"

    export const reportGTM = (customEvent) => {
        DataLayerService.pushToDataLayer(customEvent)
    }

    export const enhancedCoversionEvent = (email, phone, event) => {
        if(window.dataLayer) {
            window.dataLayer.push({
                'event': event,
                 'email_payment': email,
                 'phone_payment': phone
            });
        }
    }


