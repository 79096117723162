import React from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
    withStyles
} from '@material-ui/core';
import banner from '../../../assets/globe_upsidedown.png';

const useBannerStyle = makeStyles((theme) => ({
    root: {
        backgroundImage: 'linear-gradient(to right, #030a2d , #1c3b6d)',
        backgroundRepeat: 'no-repeat',
        borderRadius: '35px 35px 0 0',
        height: '111px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '20px 20px 0 0',
        }
    },
    image: {
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    titleContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    title: {
        fontFamily: 'Poppins-Bold',
        color: '#55ccde',
        fontSize: '20px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '17px'
        }
    },
    subtitle: {
        fontFamily: 'Poppins-Regular',
        color: 'white',
        fontSize: '12px',
        fontWeight: 200
    },
    containerBuffer : {
        height: 'calc(100% - 30px)',
        position: 'relative'
    }
}));

const CustomPaper = withStyles((theme) => ({
    root: {
        borderRadius: '35px 0 35px 35px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '30px 0 20px 20px',
        },
        position: 'relative',
        top: '-30px',
        minHeight: '100px'
    }
}))(Paper);


const SkywatchCard = ({ title, subtitle, children, Icon, actionElements }) => {
    const bannerClasses = useBannerStyle();

    return (
        <div>
            <Grid item className={bannerClasses.root}>
                <div className={bannerClasses.containerBuffer}>
                <div className={bannerClasses.image} />
                <Container  className={bannerClasses.titleContainer} >
                    <Grid container justifyContent="space-between" wrap="nowrap">
                        <Grid
                            container
                            wrap="nowrap"
                            alignItems="center"
                            >
                                <div className="margin-right-small">
                                    {Icon}
                                </div>
                            <Grid item>
                                <Typography className={bannerClasses.title}>
                                    {title}
                                </Typography>
                                <Typography className={bannerClasses.subtitle}>
                                    {subtitle}
                                </Typography>
                            </Grid>
                        </Grid>
                        {actionElements}
                    </Grid>
                </Container>
                </div>
            </Grid>
            <Grid>
                <CustomPaper elevation={0} style={{ width: '100%' }}>
                    <Grid container>
                        <Container className="margin-bottom-10 margin-top-10">
                            {children}
                        </Container>
                    </Grid>
                </CustomPaper>
            </Grid>
        </div>
    );
};

export default SkywatchCard;
