import ApiService, { HTTPMethod } from "./api.core"

class AdminApi {


    async createAgency(form) {
        form.partnership_code = form.partnership_code.toLowerCase()
        form.affiliation_code = form.affiliation_code.toUpperCase()
        var formData = new FormData()
        Object.keys(form).filter(k => k != 'logo' && k != 'favicon').map(key => {
            formData.append(key, form[key])
        })
        formData.append("logo", form['logo'])
        formData.append("favicon", form['favicon'])
        return await ApiService.fetch("partners/createPartnershipAgency", HTTPMethod.POST, formData, false)
    }

    async createAgent(form) {
        return await ApiService.fetch("partners/createAgent", HTTPMethod.POST, form)
    }

    async runBordereaux(form) {
        return await ApiService.fetch("partners/bordereaux", HTTPMethod.POST, form)
    }

    async loadAgencies() {
        return await ApiService.fetch("partners/agencies", HTTPMethod.GET)
    }

    async assignAgent(form) {
        return await ApiService.fetch("partners/assignAgent", HTTPMethod.POST, form)
    }

    async deleteAgency(agencyCode) {
        return await ApiService.fetch("partners/deleteAgency", HTTPMethod.POST, {
            agency_code: agencyCode
        })
    }

}


export default new AdminApi()