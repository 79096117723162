/* DataLayer is data that can be viewed from Google Tag Manager */

import { SessionService } from "./SessionService"


class DataLayerService {

    pushToDataLayer(data) {
        try {

          if(window.dataLayer) {
            window.dataLayer.push(data)
          }

        }
        catch(e) {}
    } 
}

export default new DataLayerService()