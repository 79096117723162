import React from "react";
import { Divider, Grid, Paper, Typography, withStyles } from "@material-ui/core";

const Box = withStyles(theme => ({
    root: {
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        padding: '20px'
    }
}))(Paper);


const AdminBox = ({children, Icon, title}) => {
    return <Box className="textcenter">
        <Grid container direction="column" alignItems="center">
            {Icon && <Icon />}
        </Grid>
        <div className="margin-top-10">
            <Typography variant="h4" className="bold">{title}</Typography>
        </div>
            <Divider/>
            {children}
    </Box>
}

export default AdminBox;