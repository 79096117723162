export const VERSION = 'v 2.0.0';

export const SKYWATCH_API_VERSION = 3;

export const STATES = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    /* "AS": "American Samoa", */
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    /* "FM": "Federated States Of Micronesia", */
    FL: 'Florida',
    GA: 'Georgia',
    /* "GU": "Guam", */
    HI: 'Hawaii',
    ID: 'Idaho',
    IN: 'Indiana',
    IA: 'Iowa',
    IL: 'Illinois',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    /* "MH": "Marshall Islands", */
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    /* "MP": "Northern Mariana Islands", */
    OK: 'Oklahoma',
    OR: 'Oregon',
    /* "PW": "Palau", */
    PA: 'Pennsylvania',
/*     PR: 'Puerto Rico',*/
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    /* "VI": "Virgin Islands", */
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
};

export const ESTIMATED_MILAGE_OPTIONS = {
    '1,000': 1000,
    '2,000': 2000,
    '3,000': 3000,
    '4,000': 4000,
    '5,000': 5000,
    '6,000 or more': 6000
};

export const BACKSPACE_KEY = 8;
export const UPPER_CASE_MIN_LETTERS_KEY = 64;
export const UPPER_CASE_MAX_LETTERS_KEY = 91;
export const LOWER_CASE_MIN_LETTERS_KEY = 96;
export const LOWER_CASE_MAN_LETTERS_KEY = 123;
export const SPACE_KEY = 32;

export const IS_ACH_ACTIVE = true;
export const IS_MVR_ACTIVE = true;

export const AIRPORT_NOT_FOUND = 'Airport not found';

export const STATE_EXPIRY_TTL = 1000 * 60 * 60 * 24 * 2; // 2 days

export const PAYMENT_METHODS = {
    applePay: {
        name: 'Apple pay'
    },
    credit: {
        name: 'Credit/debit'
    },
    googlePay: {
        name: 'Google pay'
    }
};

export const WALLETS = ['Apple pay', 'Google pay'];

export const DEBOUNCE_TIME = 250;

export const THREE_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 7 * 3;

export const MAX_FUTURE_DATE_DAYS = 60;

export const PHONE_NUMBER_REGEX = /^[0-9*\+-]+$/;

export const NNUMBER_REGEX =
    /^(\d{0,1}[N,n])?[1-9]((\d{0,4})|(\d{0,3}[A-Za-z])|(\d{0,2}[A-Za-z]{2}))$/;

export const POBOX_REGEX =
    /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;

export const TRIA_LINK = 'https://www.skywatch.ai/tria';

export const COUNTRIES = { US: 'US', CANADA: 'Canada', UK: 'UK' };

export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WEIRD_GENERATED_GUID = '00000000-0000-0000-0000-000000000000';

export const HULL_MAX_LENGTH_SERIAL_NUMBER = 16;
export const HULL_MIN_LENGTH_SERIAL_NUMBER = 6;
export const UK_HULL_MIN_LENGTH_SERIAL_NUMBER = 1;

export const PAYMENT_FEE = 2;

export const VIN_EXPLANATIONS =
    "VINs are usually stamped on the steering neck. If it's not located there, you can find it etched on the motor. Move the handlebars to create a clear view of the right side of the frame, where the steering attaches. The VIN will appear at the bottom of the cylinder.";

export const VIOLATIONS = [
    'Animal Collision',
    'At Fault Accident',
    'At Fault Accident Bodily Injury To Others',
    'At Fault Accident Physical Damage Only',
    'Attempting To Elude Officer',
    'Backing Illegally',
    'Careless/ Negligent Driving',
    'Cellular Telephone Infraction',
    'Comprehensive Claim',
    'Defective Equipment',
    'Deviating Lane Of Traffic',
    'Drive Under Influence Of Alcohol/ Drug',
    'Driving Against Traffic',
    'Driving Too Fast For Conditions',
    'Fail To Obey Traffic Control',
    'Failure To Control Vehicle',
    'Failure To Dim Lights',
    'Failure To Report Acciden',
    'Failure To Signal',
    'Failure To Stop',
    'Failure To Stop After Accident',
    'Failure To Stop For School Bus',
    'Failure To Yield',
    'Following Too Close',
    'Illegal Riding',
    'Illegal Turn',
    'Impeding Traffic',
    'Implied Consent / Refuse Test',
    'Improper Backing',
    'Improper Signal',
    'Inattentive Driving',
    'Not At Fault Accident',
    'Obstructing Traffic',
    'Obstructing View Or Control',
    'Open Intoxicant',
    'Oper After Revocation / Suspension',
    'Operating Without Lic / Exp Lic',
    'Passing Illegally',
    'Reckless / Negligent Driving',
    'Reckless Homicide',
    'Safety Violation',
    'Single Vehicle Accident',
    'Speeding 09 Over',
    'Speeding 10-19 Over',
    'Speeding 20 Over',
    'Unnecessary Acceleration'
];
