import React, { useReducer, createContext, useEffect, useContext } from "react"
import { SessionService } from "../../services/SessionService";
import SESSION_ACTIONS, {useActions} from "./actions"

const defaultState = {
    user: null,
    isLoggedIn: !!SessionService.getStoredToken(),
    hasCreditCard: false,
    impersonationDetails: null,
    isAdmin: false,
    hasMultipleQuotes: null,
    hasPolicies: null,
    activeQuoteId: null,
}

const reducer = (state, action) => {
  const {payload} = action;
    switch (action.type) {
      case SESSION_ACTIONS.USER_LOGGED_IN:
        return {...state, isLoggedIn: true, user: {email: payload.email}, hasMultipleQuotes: payload.has_multiple_quotes, hasPolicies: payload.has_policies, activeQuoteId: payload.active_quote_id};
      case SESSION_ACTIONS.USER_LOGGED_OUT:
        return {...state, ...defaultState, isLoggedIn: false};
      case SESSION_ACTIONS.PASSWORD_LOGIN:
        return {...state, isAdmin: payload.isAdmin, isLoggedIn: true, user: {email: payload.email}, hasMultipleQuotes: payload.has_multiple_quotes, hasPolicies: payload.has_policies, activeQuoteId: payload.active_quote_id};
      case SESSION_ACTIONS.LOAD_USER_PROFILE:
        return {...state, user: action.payload.profile }
      case SESSION_ACTIONS.SET_QUOTE:
        return {...state, quoteId: action.payload }
      case SESSION_ACTIONS.IMPERSONATION:
        return {...state, impersonationDetails: payload, isLoggedIn: true, hasMultipleQuotes: payload.has_multiple_quotes, hasPolicies: payload.has_policies, activeQuoteId: payload.active_quote_id}
      case SESSION_ACTIONS.LOAD_USER:
        return {...state, user: { email: payload.email }, isLoggedIn: true, isAdmin: payload.isAdmin, hasMultipleQuotes: payload.has_multiple_quotes, hasPolicies: payload.has_policies, activeQuoteId: payload.active_quote_id} 
      case SESSION_ACTIONS.EXIT_IMPERSONATION:
        return {...state, impersonationDetails: null }
      case SESSION_ACTIONS.DEEPLINK_LOGIN:
        return {...state, user: { email: payload.email }, isLoggedIn: true, isAdmin: payload.isAdmin }
      default: 
        return state;
    }
};

export const SessionContextStore = createContext(defaultState)

const SessionContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const actions = useActions(dispatch);
    const { setImpersonation, loadUser } = actions;

    useEffect(() => {
      setImpersonation();
      loadUser();
    }, [])

    return (<SessionContextStore.Provider value={{ ...state, ...actions}}>
            {children}
        </SessionContextStore.Provider>)   
}

export const useSession = () => useContext(SessionContextStore);

export default SessionContext

