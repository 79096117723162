export const getInstallmentsDetails = (totalPremium, installmentsType,installmentsFee, totalSurcharge) => {
    let installmentAmount = 0
    let additionalPaymentCount = 0
    const today = new Date();
    switch (installmentsType) {
        case 'TwoMonth':
            installmentAmount = totalPremium / 2
            additionalPaymentCount = 1
        break;
        case 'ThreeMonth':
            installmentAmount = totalPremium / 3
            additionalPaymentCount = 2
        break;
        case 'FourMonth':
            installmentAmount = totalPremium / 4
            additionalPaymentCount = 3
        break;
        default:
            break;
    }

    const installments = [{
        installment: {name: 'First payment', amount: installmentAmount},
        fee: {amount: installmentsFee, name: 'Installments Fee'},
        tax: {amount: totalSurcharge, name: 'Tax'},
        total:{amount: `$${(installmentAmount + installmentsFee + totalSurcharge).toFixed(2)}`, name: "Total Due Today"}
     }]

    const dateFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' });
    for (let i = 1; i <= additionalPaymentCount; i++) {
        const newDate = new Date(today);
        newDate.setMonth(today.getMonth() + i);
        
        installments.push({
        installment: {
          name: dateFormatter.format(newDate),
          amount: installmentAmount
        }});
      }
    
      return installments
}

export const getDueTodayInstallmentPayment = (totalPremium, installmentsType,installmentsFee) => {
    let installmentAmount = 0
    switch (installmentsType) {
        case 'TwoMonth':
            installmentAmount = totalPremium / 2
        break;
        case 'ThreeMonth':
            installmentAmount = totalPremium / 3
        break;
        case 'FourMonth':
            installmentAmount = totalPremium / 4
        break;
        default:
            break;
    }

    return installmentAmount + installmentsFee
}
