import React, { useReducer, createContext, useEffect } from "react"
import PAYMENT_ACTIONS, {useActions} from "./actions"

const defaultState = {
    clientSecret: null,
    active: null,
    hasPaymentMethod: false,
    shouldCharge: false
}

const reducer = (state, action) => {
    const payload = action.payload;
    switch (action.type) {
        case PAYMENT_ACTIONS.LOAD_CLIENT_SECRET:
            return {...state, clientSecret: payload };
        case PAYMENT_ACTIONS.GET_ACTIVE_PAYMENT_METHOD:
            return {...state, active: payload, hasPaymentMethod: !!payload };
        case PAYMENT_ACTIONS.SET_CHARGE_FLAG:
            return {...state, shouldCharge: payload};
      default: 
        return state;
    }
};

export const PaymentContextStore = createContext(defaultState)

const PaymentContext = ({children}) => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const actions = useActions(dispatch, state);

    useEffect(() => {
        actions.loadClientSecret().then(() => actions.getActivePaymentMethod());
    }, [])

    const setFlag = (value) => 
    {
        dispatch({type: PAYMENT_ACTIONS.SET_CHARGE_FLAG, payload: value})
    }

    return (<PaymentContextStore.Provider value={{ ...actions, ...state, setFlag }}>
            {children}
        </PaymentContextStore.Provider>)   
}

export default PaymentContext

