import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl, KPretty } from '../services/utils';
import { Transformer } from "../models/Transformer";
import dayjs from "dayjs";

class CoveragesApi {

    printVehicleCoverages(vehicleCoveragePrices){
        let totalPrice = 0;
        for (let vehIndex in vehicleCoveragePrices){
            let veh = vehicleCoveragePrices[vehIndex];
            console.log('\tVehicle', vehIndex);
            for (let covKey in veh.coveragePrices){
                let cov = veh.coveragePrices[covKey];
                console.log('\t\tCoverage: ', covKey, 'Annual Price: ', cov.annualPrice);
                totalPrice += cov.annualPrice;
                if (cov.factorNameToValue){
                    let factors = "";
                    for (let factorKey in cov.factorNameToValue){
                        factors += factorKey + ": " + cov.factorNameToValue[factorKey]+ ", ";
                    }
                    console.log('\t\tFactors: ', factors);
                }
            }
        }
        console.log('\tTotal annual cost: ', totalPrice);
    }

    async loadPlans() {
        let res = await ApiService.fetch('coverages/plans', HTTPMethod.GET);
        if(res.ok) {
            res.parsedData = Transformer.initializeFromDataDeep({}, res.data)
            for (let planIndex in res.parsedData.plans){
                let plan = res.parsedData.plans[planIndex];
                console.log(plan.label);
                this.printVehicleCoverages(plan.vehicleCoveragePrices);
            }
        }
        return res;
    }

    async getClearance(quote_id){
        let res =  await ApiService.fetch(`coverages/clearance/${quote_id}`, HTTPMethod.GET);
        return res;
    }

    async loadOptions(quote_id) {
        const mapPrice = {
            5000: '$5,000',
            1000000: '$1M',
            500000: '$0.5M',
            100000: '$100K',
            200000: '$200K'
        }
        let res =  await ApiService.fetch(`coverages/options/${quote_id}`, HTTPMethod.GET);
        return res;
    }
    
    async saveCoverages(data) {
        const now = new Date()
        data.start_date.setMinutes(now.getMinutes())
        data.start_date.setHours(now.getHours())
        data.start_date = dayjs(data.start_date).format();
        return await ApiService.fetch(`coverages/${data.quote_id}`, HTTPMethod.POST, data);
    }

    async goBack(quote_id){
        return ApiService.fetch(`coverages/back/${quote_id}`, HTTPMethod.POST);
    }

    async saveSummary(quoteId) {
        let res = await ApiService.fetch(`summary/finish-summary/${quoteId}`, HTTPMethod.POST);
        return res;
    }

    async requestQuote(data){
        const start_date = data.start_date;
        let res = await ApiService.fetch(`coverages/quote/${data.quote_id}`, HTTPMethod.POST, {start_date})
        return res
    }

    async requestOffer(configuration) {
        let res = await ApiService.fetch('coverages/offer', HTTPMethod.POST, configuration)
        if(res.ok) {
            res.data = Transformer.initializeFromDataDeep({}, res.data)
            this.printVehicleCoverages(res.data.vehicleCoveragePrices);
        }
        return res
    }

    async createDigitalSignatureDocs(offerId, optionsViolationsReqSignIds) {
        let res = await ApiService.fetch('coverages/createDigitalSignatureDocs', HTTPMethod.POST, { 
            offer_id: offerId,
            options_violations_req_sign_ids: optionsViolationsReqSignIds
        })
        // if(res.ok) {
        //     res.data = Transformer.initializeFromDataDeep({}, res.data)
        // }
        return res
    }

    async bindOffer(offerId, signature, optionsViolationsReqSignIds, externalId, isMobile) {
        let res = await ApiService.fetch('coverages/bind', HTTPMethod.POST, {
            // offer_id: offerId,
            // signature: signature, 
            // options_violations_req_sign_ids: optionsViolationsReqSignIds,
            // external_user_id: externalId,
            // is_mobile : isMobile
        })
        // if(res.ok) {
        //     res.data = Transformer.initializeFromDataDeep({}, res.data)
        // }
        return res
    }

    async underwriter(quoteId) {
        let res = await ApiService.fetch('coverages/underwriter', HTTPMethod.POST, {
            quote_id: quoteId,
        })
    }

    async bindMidtermOffer(offerId, insuranceId) {
        let res = await ApiService.fetch('coverages/midtermBind', HTTPMethod.POST, {
            offer_id: offerId,
            insurance_id: insuranceId
        })
        // if(res.ok) {
        //     res.data = Transformer.initializeFromDataDeep({}, res.data)
        // }
        return res
    }

    async hackSavePlan(offerId) {
        let res = await ApiService.fetch(`coverages/selectPlan?planId=${offerId}`, HTTPMethod.POST, {
            plan_id: offerId
        })
        return res
    }

    async getQuotePDF(offerId) {
        let res = await ApiService.fetch(`coverages/downloadQuotePdf?offerId=${offerId}`, HTTPMethod.GET)
        return res
    }
}

export default new CoveragesApi()