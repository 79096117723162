

import React, { useContext } from "react"
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import AdminBox from "./AdminBox";
import QuoteUserBox from './Broker/QuoteUserBox';
import ManageAccountsBox from "./Broker/ManageAccountsBox";
import BordereauxBox from "./Broker/BordereauxBox";

export const Admin = () => {
    return (
        <Container>
            <Grid style={{height: '100%'}} container spacing={3} alignContent="center" alignItems="center">
                <Grid item container alignItems="center" direction="column" className="margin-bottom-basic">
                    <Typography variant="h2" className="margin-bottom-basic">Skywatch Admin Portal</Typography>
                    <Typography variant="body1">Hello,</Typography>
                    <Typography variant="body1" className="bold">What would you like to do?</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <QuoteUserBox />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BordereauxBox />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Admin;