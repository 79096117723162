import ApiService, { HTTPMethod } from "./api.core";
import { EquipmentType } from "../models/hull-models/EuipmentTypes";
import { Transformer } from "../models/Transformer";
import { SessionService } from "../services/SessionService";


export class HullApi {

    static async getEquipmentTypes(): EquipmentType[] {
        let res = await ApiService.fetch("users/me/equipment/types");
        let lst = []
        if(res.ok) {
            res.data.types.forEach(element => {
                lst.push(Transformer.initializeFromData(new EquipmentType(), element))
            });
        }
        return lst
    }

    static async saveDrones(drones) {
        return ApiService.fetch("users/me/drones", HTTPMethod.POST, {
            drones: drones
        });
    }

    static async saveEquipment(equipment) {
        return ApiService.fetch("users/me/equipment", HTTPMethod.POST, {
            equipment: equipment
        });
    }

    static async getUserEquipment() {
        return ApiService.fetch("users/me/equipment");
    }

    static async getUserDrones() {
        let res = await ApiService.fetch("users/me/drones");
        if(res.data && res.data.drones) {
            res.data.drones = res.data.drones.map(d => {
                d.type = 'drone'
                return d
            })
        }
        return res
    }

    static async uploadDroneImage(dronePhoto) {
        return new Promise(function (resolve, reject) {
          let xhr = new XMLHttpRequest();
          xhr.open('POST', process.env.REACT_APP_SERVER_URL + 'users/me/drones/images');
          xhr.setRequestHeader('Authorization', SessionService.getToken() ? 'Bearer ' + SessionService.getToken() : null);
          var form = new FormData();
          form.append("file", dronePhoto)
          xhr.onload = function () {
              if (this.status >= 200 && this.status < 300) {
                  resolve(JSON.parse(xhr.response));
              } 
              else {
                  reject({
                      status: this.status,
                      statusText: xhr.statusText
                  });
              }
          };
          xhr.onerror = function () {
              reject({
                  status: this.status,
                  statusText: xhr.statusText
              });
          };
          xhr.send(form);
        });
      }
}