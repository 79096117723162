import React, { Suspense, lazy } from 'react'
import { isMobile, MobileView, BrowserView } from 'react-device-detect'
import { Dialog, Container, withStyles } from '@material-ui/core'
import FlexView from 'react-flexview/lib'
import CircledPopUpFrame from '../Core/CircledPopUpFrame';
import { BackButtonTitle } from '../Core/BackButtonTitle';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const SideMenu = lazy(() => import('../SideMenu/SideMenu'));

interface SkywatchDialogProps {
    open: Boolean,
    fullScreen: Boolean,
    closeDialog: Function,
    children: any,
    closeTitle: String,
    circleHeader: any,
    maxWidth: String,
    shouldHaveHeight: Boolean,
    shouldScroll: Boolean
}

export const SkywatchDialog = ({open, closeDialog, children, circleHeader, closeTitle, fullScreen, maxWidth = 'md', shouldScroll = false, shouldHaveHeight,...props }: SkywatchDialogProps) => {
    return (<Dialog
                id="markel-form"
                BackdropProps={{style: {backgroundColor: 'white', opacity: isMobile ? 1 : 0.64}}}
                fullWidth
                fullScreen={fullScreen}
                open={open}
                onClose={closeDialog}
                PaperProps={{style: {backgroundColor: 'transparent', boxShadow: 'none', overflowY: shouldScroll ? 'auto':'visible'}}}
                maxWidth={maxWidth}>
                    { isMobile && <SimpleBar autoHide={false}
                                    style={{maxHeight: '90vh'}}
                                    forceVisible
                                    
                                    >
                            <Suspense fallback={<div />}>
                                <SideMenu />
                            </Suspense>
                            <div className='content-container-form'>
                                    <BackButtonTitle onBackPressed={closeDialog} title={closeTitle} />
                                    <br />
                                    {children}
                            </div>
                    </SimpleBar> }
                    <BrowserView>
                        <CircledPopUpFrame onCancel={closeDialog} circleHeader={circleHeader} shouldHaveHeight={shouldHaveHeight}>
                            {children}
                        </CircledPopUpFrame>
                    </BrowserView>
            </Dialog>)
}