import React, { useEffect } from "react";
import { Checkbox, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const ratings = ['Instrument rating','Aircraft single engine land',
    'Aircraft multi-engine land',
'Aircraft single engine sea',
'Glider',
]

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const PilotRatingAutocomplete = ({name}) => {
    const { control, watch, errors, getValues, reset} = useFormContext();
    const certification = watch("certification");
    const show = certification !== 'Student';

    useEffect(() => {
        let curRating = getValues('pilot_rating') !== undefined ? getValues('pilot_rating') : []
        const  currentValues = getValues();

        if (certification === 'Airline Transport Pilot' && (curRating.length == 0 || !curRating.includes(ratings[0]))) {
            curRating.push(ratings[0])
            reset({...currentValues, pilot_rating: curRating})
            return
        }
        if(certification === 'Student') {
            reset({...currentValues, pilot_rating: []})
            return
        }
    }, [certification])

    return (
        <>
            {show && (
                <>
                    <Typography variant="h4" className="grey">
                        Pilot rating
                    </Typography>
                    <Controller
                        render={(props) => (
                            <Autocomplete
                                {...props}
                                multiple
                                options={ratings}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </React.Fragment>
                                  )}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Rating"
                                        error={!!errors[name]}
                                        helperText={errors[name]?.message ?? ''}
                                    />
                                )}
                                onChange={(_, data) => props.onChange(data)}
                                className="margin-top-8"
                            />
                        )}
                        name={name}
                        control={control}
                    />
                </>
            )}
        </>
    );
}

export default PilotRatingAutocomplete;