import React from 'react';

export const TrashIcon = () => (
    <svg width="13" height="15" viewBox="0 0 13 15" >
    <g stroke="#2D2D2D" strokeWidth=".81" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(1 2.405)">
            <rect width="11" height="2.108" rx="1.054"/>
            <path d="M1.1 2.108h8.8v8.131c0 .749-.492 1.356-1.1 1.356H2.2c-.608 0-1.1-.607-1.1-1.356V2.11zM4.033 4.216v4.216M6.967 4.216v4.216"/>
        </g>
        <path d="M5.033 2.405h3.3V1.201C8.333 1.09 8.15 1 7.921 1H5.446c-.228 0-.413.09-.413.2v1.205z"/>
    </g>
</svg>
)