import React, { useContext } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import PilotBasicDetailsSection from '../../../Pilot/PilotSections/PilotSections/PilotBasicDetailsSection';
import PilotRatingSection from '../../../Pilot/PilotSections/PilotSections/PilotRatingSections';
import PilotHoursSection from '../../../Pilot/PilotSections/PilotSections/PilotHoursSection';
import PilotWaiverSection from '../../../Pilot/PilotSections/PilotSections/PilotWaiverSection';
import { QuoteContextStore } from '../../../../context/QuoteContext/QuoteContext';
import { lazyWithPreload } from '../../../../utilities/RLazyLoading';
import { LayoutMain } from '../../../Layout/LayoutMain';

const EditPilotRenewal = () => {
    const { aircrafts,quote_id,updateRenewalQuote } = useContext(QuoteContextStore);
    const defaultAircraft = aircrafts[Object.keys(aircrafts)[0]];
    const {loadQuote } = useContext(QuoteContextStore);
    const PilotStep = lazyWithPreload(() =>
    import('../../../Pilot/PilotStep').then((module) => ({
        default: module.PilotStep
    })) 
    );
    return (
        <React.Fragment>
        <LayoutMain>
            <div className="policy-management-title">
                <Grid style={{display:'flex', flexDirection:'column'}} container justifyContent="left">
                    <Typography style={{height:'37px'}} variant="h1" className="blue">Edit Renewal Details</Typography>
                        <PilotStep isRenewal={true}/>
                </Grid>
            </div>
        </LayoutMain>
            
        </React.Fragment>
    );
};

export default EditPilotRenewal;
