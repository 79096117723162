import React from "react"
import { Grid, Typography } from "@material-ui/core"
import PilotRatingAutocomplete from "../RatingAutocomplete";
import PilotCertificateSelect from "../PilotCertificateSelect";

const PilotRatingSection = () => 
    <React.Fragment>
        <Grid item xs={12} md={5}>
            <Typography variant="h4" className="grey">Certification</Typography>  
                <PilotCertificateSelect/>
        </Grid>
        <Grid item xs={12} md={7}>
            <PilotRatingAutocomplete name="pilot_rating" />
        </Grid> 
    </React.Fragment>

export default PilotRatingSection