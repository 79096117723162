import React from "react"
import { Button } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';



const CustomBlueButton = withStyles(theme => ({
    root: {
        boxShadow: '0 4px 8px 0 rgb(44 39 56 / 20%)',
        padding: '10px 16px 13px 20px',
        borderRadius: 5,
        backgroundColor: '#164076',
        color: 'white',
        fontFamily: 'Poppins-Regular',
        fontWeight: 'bold',
        textTransform: 'inherit',
        '&:hover' : {
            backgroundColor: '#071628'
        }
    },
}))(Button);

export const BlueButton = ({children, className, slim, ...props}) => {
    return (
        <CustomBlueButton {...props} variant="contained" disableElevation style={slim ? {padding: '6px 16px'} : {}} className={`${className}`}>{children}</CustomBlueButton>
    )
}
