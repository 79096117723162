import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import React from "react"
import {ReactComponent as SideBarFooter} from './../../assets/flow-globe.svg';
import banner from '../../assets/globe_upsidedown.png'

const LayoutSideCard = ({children, ...props}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
            <>
            { !isMobile ? <>
                <Grid container direction="column" className="step-title layout-card-side-content">
                    {children} 
                </Grid>
                <SideBarFooter className="layout-card-footer-globe"/>
                <span className="layout-card-side-tailend" />
            </>
            : <Grid item xs={12} className="layout-card-banner-mobile">
                <img src={banner} className="layout-card-globe-mobile"/>
                {children}
            </Grid>}
            </>
    )
}

export default LayoutSideCard;