import { Link, withStyles } from '@material-ui/core';
import React from 'react';


const SkywatchLink = withStyles(theme => ({
    root: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
  }))(Link);

export default SkywatchLink;