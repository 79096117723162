import * as yup from 'yup';

const cancellationFormSchema = yup
.object()
  .shape({
    is_full_refund: yup.boolean(),
    amount: yup.string()
    .when(['is_full_refund'], (is_full_refund, schema) => {
        return is_full_refund ? schema : schema.typeError('Required').required('Required');
    }),
    start_date: yup.date(),
    end_date: yup.date(),
    cancellation_date: yup.date().typeError('Required').required("Required")
    .when(['start_date', 'end_date'], (start_date, end_date, schema) => {
        return schema.test("cancellation_date", "Must be between policy start and end date", (cancellation_date) => { 
            const cd = new Date(cancellation_date);
            const ed = new Date(end_date);
            const sd = new Date(start_date);
            return ed >= cd || sd <= cd;
        });
    })
})
.required();

export default cancellationFormSchema;