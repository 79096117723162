import { Box, Button, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { ReactComponent as WarningIcon } from '../../../assets/warning-icon.svg'

import React, { useState } from 'react';
import { PaymentFailurePopup } from '../../PopUps/PaymentFailurePopup';
import dayjs from 'dayjs';

const PaymentNotice = ({id, date, amount, onRechargePayment}) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [ paymentFailureOpen, setPaymentFailureOpen] = useState(false)


    const closePaymentPopUp = () => {
        setPaymentFailureOpen(false)
    }

    const onConfirm = async () => {
      await onRechargePayment(id)
    }


    const openPopUp = () => {
        setPaymentFailureOpen(true)
    }

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        borderRadius: '8px',
        background:'#FCFCFD',
        width: '100%',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        marginTop: '1em'

      }}
    >
        <Box className="margin-left-small" sx={{display:'inline-flex'}}>
            <WarningIcon/>
            <div className='margin-left-small'>
                <Typography  variant="title" fontWeight="bolder" gutterBottom>
                Failed payment noticed
                </Typography>
                <Typography variant="body2" color="textSecondary">
                This user had a previous payment failure of <strong>${amount}</strong> at <strong>{dayjs(new Date(date)).format('MM/DD/YYYY')}</strong>
                </Typography>
            </div>
        </Box>
        <Button style={{textTransform: 'none'}} onClick={openPopUp} className={isMobile ? "margin-top-medium" : "" } variant="contained" color="primary">
            <strong>Recharge ${amount}</strong>
        </Button>
        <PaymentFailurePopup amount={amount} open={paymentFailureOpen} onCancel={closePaymentPopUp} onConfirm={onConfirm}/>
    </Paper>
  );
};

export default PaymentNotice;
