import { Checkbox, FormControlLabel } from "@material-ui/core";
import React,{ memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const ControlledCheckBoxContent = ({ control, errors, name, label, onChange, ...props }) => 
    <Controller 
        control={control}
        name={name}
        render={({onChange: paramsOnChange, ...params}) => (
            <FormControlLabel
                control={<Checkbox {...params} checked={params.value ?? false} {...props} color="primary" onChange={(e, data) => {
                    paramsOnChange(e.target.checked);
                    if(!onChange) return;
                    onChange(e.target.checked);
                }} />}
                label={label}/>
        )}
    />
;

export const ControlledCheckBox = (props) => {
    const { control, errors } = useFormContext();
    return <ControlledCheckBoxContent {...props} {...{control, errors}} />
}