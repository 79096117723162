
import { createTheme } from '@material-ui/core/styles';
import BrokerConfigService from '../services/BrokerConfigService';

export function createSkywatchMaterialTheme(theme) {
  const customTheme =  createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: theme.blue,
        light: theme.light,
        contrastText: '#fff',
        
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: theme.secondary,
        // dark: will be calculated from palette.secondary.main,
      },
      grey: {
        main: theme.grey,
        light: theme.lightGrey,
        superLight: theme.superLightGrey
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      h1:{
        fontSize:32,
        fontWeight: 600,
        fontFamily: "Poppins-Bold",
        '@media (max-width:760px)': {
          fontSize: 20
        }
      },
      h2:{
        fontSize: 26,
        fontWeight: 600,
        fontFamily: "Poppins-Bold",
        '@media (max-width:760px)': {
          fontSize: 20
        }
      },
      h3:{
        fontSize: 20,
        fontWeight: 600,
        fontFamily: "Poppins-Bold",
        '@media (max-width:760px)': {
          fontSize: 20
        }
      },
      h4: {
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "Poppins-regular"
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "Poppins-regular"
      },
      body1: {
        color: '#6e6e72',
        fontSize: 15,
        lineHeight: '1.2',
        fontWeight: 500,
        fontFamily: "Poppins-regular"
      },
      body2: {
        color: '#6e6e72',
        fontSize: 14,
        lineHeight: '1.2',
        fontWeight: 500,
        fontFamily: "Poppins-regular"
      }
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
            background: '#fff',
            color: 'black',
            fontWeight: 300,
            boxShadow: '0 4px 8px 0 rgba(44, 39, 56, 0.04)',
            borderRadius: 7,
            '&$focused $notchedOutline': {
              borderColor: '#55ccde',
              borderWidth: 1,
            },
            '& fieldset': {
              border: 'solid 1px #e9eef3'
            },
            '&:hover fieldset': {
              borderColor: `#55ccde !important`,
            },
        },
      },
      MuiFormControlLabel: {
        root: {
          '& $label': {
            fontFamily: "Poppins-regular",
            color: '#77869e'
          }
        }
      },
      MuiFormLabel: {
        root: {
            '&$focused': {
                color: theme.blue,
            },
          color: '#8898b5',
          fontWeight: 500
        }
      },
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  });

  return customTheme;
}