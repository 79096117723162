import React from 'react'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import FlexView from 'react-flexview/lib';
import './BackButtonTitle.css'

export const BackButtonTitle = ({onBackPressed, title, ...props}) => {

    return (<FlexView hAlignContent={'center'} vAlignContent={'center'} className="back-button-title margin-top-basic" onClick={onBackPressed}>
        <KeyboardBackspaceIcon className="black back-button-backico" />
        <div className="text-title black bold textcenter">{title}</div>
    </FlexView>)
}