import React from "react";
import { Button, withStyles } from "@material-ui/core";

const CardButton = withStyles((theme) => ({
    root: {
        minWidth: '130px',
        backgroundColor: 'rgba(85, 204, 222, 0.18)'
    },
    outlined: {
        borderColor: theme.palette.primary.light,
        border: '2px solid'
    },
    label: {
        color: theme.palette.primary.light,
        textTransform: 'none',
        
    }
}))(Button);

export default CardButton;