import React, { memo } from 'react';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller, useFormContext } from "react-hook-form";
import { FirebaseConfigKey, FirebaseService } from '../../../services/FirebaseService';
import EventIcon from '@material-ui/icons/Event';


const ControlledDatePickerContent = memo(({name, errors, getValues, control, className, disableFuture, onChange, ...props}) => (
    <Controller
    name={name}
    control={control}
    render={({ ref, onChange: datePickerChange,...rest }) => (
      <KeyboardDatePicker
        {...props}
        disableFuture={disableFuture}
        placeholder="MM/DD/YYYY"
        openTo={props.openTo ?? "year"}
        views={["year", "month", "date"]}
        keyboardIcon={props.keyboardIcon ?? <EventIcon className="backbuttongray" />}
        className={className}
        id="date-picker-dialog"
        format={FirebaseService.getValue(FirebaseConfigKey.LONG_DATE_FORMAT)}
        error={!!errors[name]}
        inputVariant={props.variant ?? 'outlined'}
        helperText={errors[name]?.message}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        onChange={(e, data) => {
          datePickerChange(data);
          if(!onChange) return;
          onChange(data);
        }}
        {...rest}
      />
    )}
  />
))

export const ControlledDatePicker = (props) => {
    const {errors, control, getValues} = useFormContext();
    return <ControlledDatePickerContent { ...{errors, control, getValues}} {...props} />
}
