import React from 'react';
import { SkywatchDialog } from '../PopUps/SkywatchDialog';
import FlexView from 'react-flexview/lib';
import { Button } from '@material-ui/core';
import { ReactComponent as ConfirmChrageIcon } from '../../assets/confirm-charge.svg'



export const PaymentFailurePopup = ({open, onCancel, onConfirm, amount}) => {

  const handleClose = () => {
    onCancel()
  };

  const Confirm = async () => {
      await onConfirm()
      handleClose()
  }
  return (
      <SkywatchDialog
        open={open}
        closeDialog={handleClose}
        maxWidth={'xs'}
        closeTitle={"Your Policy"}>
        <FlexView column>
            <ConfirmChrageIcon/>
          <div className="text-title blue bold margin-top-basic textleft margin-bottom-basic">Confirm Charge</div>
          <div className="text-small black textleft margin-bottom-basic">This will charge the customer <strong>${amount}</strong>. Are you sure you want to proceed?</div>
          <br />
          <FlexView className='full-width' >
            <Button variant='contained' style={{backgroundColor: '#164076', borderRadius:'0.5em', textTransform: 'none'}} className="margin-bottom-basic bolder white full-width" onClick={Confirm}>Confirm</Button>
            <Button variant='outlined' onClick={handleClose} style={{borderRadius: '0.5em', textTransform: 'none'}} className="margin-bottom-basic margin-left-small bolder grey full-width">Cancel</Button>
            </FlexView>
        </FlexView>
      </SkywatchDialog>
  );
}