import React from 'react'
import "./Bureaucracy.scss"

const TermsAndConditions = () => {
    return (
    <div className='terms-container'>
        <p>This policy for which you are submitting this application does not apply to occurrences involving: Skydiving or skydiving related activities; power line, pipeline or highline patrol; aerial photography or cinematography requiring a FAA Certificate of Waiver; any form of hunting; animal herding; taxi, takeoff or alighting on water, while the aircraft is equipped with floats; taxi, takeoff or landing on snow or ice, while the aircraft is equipped with skis; any use involving a charge intended to result in financial profit to the Insured.</p>
        <p>Aircraft use - this application is for insurance for a non-commercial policy.</p>
        <p>All representations herein are declared to be true and complete to the best of my/our knowledge and no information has been withheld or suppressed. I/we agree that this application and the terms and conditions of the policy in use by the insurers shall be the basis of any contract between me/us and the insurers. I hereby authorize the insurers to investigate all representations contained herein.</p>
        <p>Insurance through Global Aerospace, Inc. will not be effective until the application and premium payment in full have been received and the applicant is in receipt of a written confirmation from the producer or from Global Aerospace, Inc. that the policy is in effect.</p>
        <p>SUBMISSION OF THIS APPLICATION DOES NOT COMMIT THE INSURER TO ANY LIABILITY NOR MAKE THE APPLICANT LIABLE FOR ANY PREMIUM UNLESS AND UNTIL THE INSURER AGREES TO EFFECT THIS INSURANCE.</p>
        <p>THE APPLICANT FULLY UNDERSTANDS THAT THE POLICY WILL INCLUDE TERMS, CONDITIONS AND EXCLUSIONS WITH RESPECT TO THE INSURANCE AFFORDED. Any questions about the insurance afforded under this program should be directed to the insurance producer.</p>
        <p>Global Aerospace, Inc. underwrites on behalf of the Global Aerospace Underwriters Pool. Policies are issued by US affiliates or subsidiaries of Global Aerospace Underwriters pool members. Details on the US policy issuing companies is published on the Global Aerospace, Inc. website atthe following location: www.global-aero.com/about/financial security</p>
        <p>FRAUD STATEMENTS</p>
        <p>Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects that person to criminal and civil penalties (In Oregon, the aforementioned actions may constitute a fraudulent insurance act which may be a crime and may subject the person to penalties). (In New York, the civil penalty is not to exceed five thousand dollars </p>
        <p>($5,000) and the stated value of the claim for each such violation). (Not applicable in AL, AR, AZ, CO, DC, FL, KS, LA, ME, MD, MN, NM, OK, PR, RI, TN, VA, VT, WA and WV).</p>
        <p>APPLICABLE IN AL, AR, AZ, DC, LA, MD, NM, RI and WV</p>
        <p>Any person who knowingly (or willfully in MD) presents a false or fraudulent claim for payment of a loss or benefit or who knowingly (or willfully in MD) presents false information in an application for insurance is guilty of a crime and may be subject to fines or confinement in prison.</p>
        <p>APPLICABLE IN COLORADO</p>
        <p>It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the department of regulatory agencies.</p>
        <p>APPLICABLE IN FLORIDA and OKLAHOMA</p>
        <p>Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony (In FL, a person is guilty of a felony of the third degree).</p>
        <p>APPLICABLE IN KANSAS</p>
        <p>Any person who, knowingly and with intent to defraud, presents, causes to be presented or prepares with knowledge or belief that it will be presented to or by an insurer, purported insurer, broker or any agent thereof, any written statement as part of, or in support of, an application for the issuance of, or the rating of an insurance policy for personal or commercial insurance, or a claim for payment or other benefit pursuant to an insurance policy for commercial or personal insurance which such person knows to contain materially false information concerning any fact material thereto; or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act.</p>
        <p>APPLICABLE IN MAINE, TENNESSEE, VIRGINIA and WASHINGTON</p>
        <p>It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.</p>
        <p>APPLICABLE IN PUERTO RICO</p>
        <p>Any person who knowingly and with the intention of defrauding presents false information in an insurance application, or presents, helps, or causes the presentation of a fraudulent claim for the payment of a loss or any other benefit, or presents more than one claim for the same damage or loss, shall incur a felony and, upon conviction, shall be sanctioned for each violation by a fine of not less than five thousand dollars ($5,000) and not more than ten thousand dollars ($10,000), or a fixed term of imprisonment for three (3) years, or both penalties. Should aggravating circumstances be present, the penalty thus established may be increased to a maximum of five(5) years, if extenuating circumstances are present, it may be reduced to a minimum of two (2) years.</p>
        <p>PAYMENT</p>
        <p>Payment for certain insurance products available on this website may be made with selected credit cards or using PayPal.  These payments are processed by a third-party payment processing service ("Payment Service").  Any payment made using the Payment Service will be deemed complete only when:</p>
        <p className='tab-1'>1.  you have submitted your name, the details of a current and valid credit card of a type that the Payment Service accepts or your PayPal account information and all other required information (the "Information");</p>
        <p className='tab-1'>2.  all Information from you has been received;</p>
        <p className='tab-1'>3.  a charge against your selected credit card or PayPal account has been made; and</p>
        <p>IF ANY PAYMENT IS REVERSED OR DISHONORED FOR ANY REASON WHATSOEVER, NO PAYMENT WILL BE DEEMED TO HAVE BEEN MADE TO US, AND WE WILL RESCIND OR CANCEL YOUR POLICY IN ACCORDANCE WITH ITS TERMS.</p>
        <p>By providing and submitting the Information you warrant that:</p>
        <p className='tab-1'>1. we are authorized to use the Information to request and obtain payment from your credit card issuer or PayPal;</p>
        <p className='tab-1'>2. the credit card or PayPal account is issued in your name and that you are authorized to incur charges against it; and</p>
        <p className='tab-1'>3. the Information you have provided is accurate and complete.</p>
        <p>We may, in our sole discretion and at any time without prior notice change the credit cards or other payment methods that we accept.</p>
        <p>We make no warranties, representations or guarantees that the Payment Service will operate as intended, remain secure, or that its operation will be continuous or be uninterrupted.  All implied terms, conditions and warranties regarding the functionality and security of the Payment Service are disclaimed to the fullest extent possible.</p>
        <p>ELECTRONIC CONSENT</p>
        <p>By opening your account, you agree to receive all documents and communications (including, but not limited to: the policy, endorsements, certificates or other documents concerning this transaction) through electronic means.  You agree that electronically delivered documents and communications will have the same legal status, to the extent permitted by law, as paper documents.</p>
        <p>You also agree that:</p>
        <p className='tab-1'>1. your decision to receive documents electronically is voluntary, but can be revoked at any time by you by contacting your licensed insurance producer (we retain the right to impose charges or fees for the delivery of paper copies of documents that could have been sent to you electronically);</p>
        <p className='tab-1'>2. in order to access and retain copies of your documents and communications, you have Internet access with a compatible browser; and</p>
        <p className='tab-1'>3. you have provided us with a current and active email address for you, and that you will notify us of any change in your email address.</p>
        <p>CHANGES</p>
        <p>We reserve the right to terminate or change the Online Transaction Terms and Conditions, in whole or in part, at any time, without prior notice to you. </p>
        <center><h3>TERRORISM RISK INSURANCE ACT OF 2002</h3></center>
        <center><p>(As amended and extended by The Terrorism Risk Insurance Extension Act of 2005 and The Terrorism Risk Insurance Program Reauthorization Acts of 2007, 2015 and 2019)</p></center>
        <p>NOTICE TO PRODUCER: The applicant must be made aware of the opportunity to purchase the coverage contained in the Applicant Disclosure described below. Any request from you to bind the coverage offered in this quote must include advice to us regarding the applicant's choice for terrorism insurance coverage. The premium stated in the disclosure is for terrorism insurance coverage only.</p>
        <p>APPLICANT DISCLOSURE: NOTICE AND OFFER OF TERRORISM INSURANCE COVERAGE</p>
        <p>You are hereby notified that under the Terrorism Risk Insurance Act of 2002, as amended, hereinafter referred to as 'the Act,' you have a right to purchase insurance coverage for losses arising out of acts of terrorism, as defined in Section 102(1) of the Act: "The term "act of terrorism" means any act that is certified by the Secretary of the Treasury - in consultation with the Secretary of Homeland Security, and the Attorney General of the United States - to be an act of terrorism; to be a violent act or an act that is dangerous to human life, property; or infrastructure; to have resulted in damage within the United States, or outside the United States in the case of an air carrier or vessel or the premises of a United States mission; and to have been committed by an individual or individuals as part of an effort to coerce the civilian population of the United States or to influence the policy or affect the conduct of the United States Government by coercion."</p>
        <p>PURCHASE OF THE INSURANCE OFFERED IN THIS DISCLOSURE HAS THE EFFECT OF NULLIFYING TERRORISM EXCLUSIONS CONTAINED IN NEW OR RENEWAL POLICIES FOR ACTS OF TERRORISM, AS DEFINED IN THE ACT. HOWEVER, ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED, AND YOUR POLICY MAY CONTAIN OTHER EXCLUSIONS THAT MIGHT AFFECT YOUR COVERAGE. IF THIS COVERAGE IS NOT PURCHASED, THE COVERAGE AFFORDED BY THE POLICY WILL BE THAT WHICH WOULD APPLY IN THE ABSENCE OF THE ACT.</p>
        <p>IF PURCHASED, COVERAGE AFFORDED FOR LOSSES CAUSED BY CERTIFIED ACTS OF TERRORISM MAY BE PARTIALLY REIMBURSED BY THE U.S. GOVERNMENT UNDER A FORMULA ESTABLISHED BY FEDERAL LAW. UNDER THIS FORMULA, IF THE AGGREGATE INSURED LOSSES EXCEED A TRIGGER AMOUNT ($200,000,000 BEGINNING ON JANUARY 1, 2020), THE U.S. GOVERNMENT GENERALLY PAYS A PERCENTAGE (80% BEGINNING ON JANUARY 1, 2020) OF COVERED TERRORISM LOSSES EXCEEDING THE STATUTORILY ESTABLISHED DEDUCTIBLE PAID BY THE INSURANCE COMPANY PROVIDING THE COVERAGE. THE PREMIUM CHARGE FOR THIS COVERAGE DOES NOT INCLUDE ANY CHARGES FOR THE PORTION OF LOSS COVERED BY THE U.S. GOVERNMENT UNDER THE ACT.</p>
        <p>YOU SHOULD ALSO KNOW THAT THE ACT, CONTAINS A PROGRAM CAP OF $100,000,000,000 THAT LIMITS U.S. GOVERNMENT REIMBURSEMENT AS WELL AS INSURERS' LIABILITY FOR LOSES RESULTING FROM CERTIFIED ACTS OF TERRORISM WHEN THE AMOUNT OF SUCH LOSSES IN ONE CALENDAR YEAR EXCEEDS THE PROGRAM CAP. IF THE AGGREGATE INSURED LOSSES FOR ALL INSURERS EXCEEDS THE PROGRAM CAP, YOUR COVERAGE MAY BE REDUCED.</p>
        <p>PREMIUM FOR TERRORISM INSURANCE COVERAGE (which is in addition to the "Total Premium", and is subject to any applicable state taxes and surcharges) IS $Zero.</p>
        <p>In accordance with the provisions of the Act, the Terrorism Risk Insurance Program shall terminate on December 31, 2027. Unless the program is renewed, extended or otherwise continued by the federal government, insurance coverage purchased for losses arising out of acts of terrorism shall terminate as of the date when any one or more of the following first occurs: (a) the policy period ends; (b) the federal Terrorism Risk Insurance Program, established by the Act, has terminated either in its entirety or as respects the type of insurance afforded by this policy; or (c) renewal, extension or continuation of the Terrorism Risk Insurance Program has become effective without a requirement to make terrorism coverage available as respects the type of insurance afforded by this policy.</p>
        <p>If the insurance is not terminated upon the occurrence of b. or c. above, insurance will remain in force without change for the remainder of the policy period, unless the company notifies you of any such change in response to any change in the federal law.</p>
        <p>If the insurance is terminated upon the occurrence of b. or c. above, pro rata unearned premium will be returned to you.</p>
    </div>
    )
}

export default TermsAndConditions;