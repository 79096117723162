import React from "react"
import { ControlledTextField } from "../../Core/Form/ControlledFields";
import { MenuItem } from "@material-ui/core"

const PilotCertificateSelect = () => 
     <ControlledTextField fullWidth={true} name="certification" id="certification" className="margin-top-8" placeholder="Certification" select>
            <MenuItem disabled selected value="default"><span className="placeholder-gray">Certification</span></MenuItem>
            <MenuItem value="Student">Student</MenuItem>
            <MenuItem value="Recreational">Recreational</MenuItem>
            <MenuItem value="Sport">Sport</MenuItem>
            <MenuItem value="Private">Private</MenuItem>
            <MenuItem value="Commercial">Commercial</MenuItem>
            <MenuItem value="Airline Transport Pilot">Airline Transport Pilot</MenuItem>
        </ControlledTextField>

export default PilotCertificateSelect;