import React, { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import aircraftApi from "../../api/aircraft.api";
import airportApi from "../../api/airport.api";
import coveragesApi from "../../api/coverages.api";
import pilotApi from "../../api/pilot.api";
import {UserApi} from "../../api/user.api";
import {LoadingContextStore} from "../LoadingContext/LoadingContext";
import { AuthApi } from '../../api/auth.api';
import { SessionService } from "../../services/SessionService";

const SESSION_ACTIONS = {
    SHOW_ERROR: "SHOW_ERROR",
    SHOW_INFO: "SHOW_INFO",
    SHOW_WARNING: "SHOW_WARNING",
    SHOW_SUCCESS: "SHOW_SUCCESS",
    SHOW_SERVER_ERROR: "SHOW_SERVER_ERROR",
    HIDE: "HIDE"
}

export const useActions = (dispatch) => {
    return {
        showError: async function({title, message, onConfirm}) {
            dispatch({
                type: SESSION_ACTIONS.SHOW_ERROR,
                payload: {title, message, onConfirm}
            })
        },
        showInfo: async function(data) {
            dispatch({
                type: SESSION_ACTIONS.SHOW_INFO,
                payload: data
            })
        },
        showWarning: async function({title, message, onConfirm}) {
            dispatch({
                type: SESSION_ACTIONS.SHOW_WARNING,
                payload: {title, message, onConfirm}
            })
        },
        showServerError: async function({title ,message}) {
            const titleError = !title ? "Server Error" : title;
            dispatch({
                type: SESSION_ACTIONS.SHOW_SERVER_ERROR,
                payload: {title: titleError, message}
            })
        },
        hide: async function() {
            dispatch({
                type: SESSION_ACTIONS.HIDE
            })
        }
    }
}

export default SESSION_ACTIONS;