
import React from 'react'
import FlexView from "react-flexview/lib"
import CloseIcon from '@material-ui/icons/Close';
import './CircledPopUpFrame.scss'
import { Container } from '@material-ui/core'

type CirclePopUpFrameProps = {
    circleHeader: Object,
    onCancel: Function,
    children: Object,
    shouldHaveHeight: Boolean
}

export default class CircledPopUpFrame extends React.PureComponent {

    prop: CirclePopUpFrameProps
    //todo : change component name from Circled

    render() {
        return (
        <FlexView vAlignContent={'center'} hAlignContent={'center'} column>
            <FlexView column hAlignContent={'center'} className="main_container">
                <div onClick={this.props.onCancel} className="close_btn_container"> <CloseIcon className="powderblue" fontSize="default" /> </div>
                <div className={`${this.props.shouldHaveHeight && "scroll-modal"} circled-popup-content`}>
                    <Container>
                        {this.props.children}
                    </Container>
                </div>
            </FlexView>
          </FlexView>)
    }
}