import React from 'react';
import { Container, Grid, Link, useMediaQuery } from '@material-ui/core';
import {
    useRouteMatch,
    Switch,
    useLocation
} from 'react-router-dom';
import './Layout.scss';
import LayoutSideCard from './LayoutSideCard';

export const LayoutMain = ({children, sideComponent}) => {
    const { path, url } = useRouteMatch();
    const location = useLocation();
    const isTablet = !useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'));

    return (
        <Grid container className="layout-container">
            <Grid item container sm={12} md={4} lg={3} className="layout-card-side">
                <LayoutSideCard>
                    {sideComponent}  
                </LayoutSideCard>
            </Grid>
            <Grid sm={12} md={8} lg={9} className="layout-card-container">
                <Container>
                        {children}
                </Container>
                <div className='grey flow-footer'>
                    <span>Insurance is provided by SkyWatch Insurance Services, Inc, an insurance agency licensed to sell property-casualty insurance products and receives compensation from insurance</span><br />
                    <span>companies for such sales. Coverage is subject to the actual policy terms and conditions; please also refer to our <Link target="_blank" href='https://www.skywatch.ai/terms-conditions'>Terms and Conditions</Link> and <Link target="_blank" href='https://www.skywatch.ai/privacy-policy'>Privacy Policy</Link>.</span>
                </div>
            </Grid>
        </Grid>
    );
};
