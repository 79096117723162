import React from "react"
import { Route, Redirect } from "react-router-dom";
import { SessionService } from "../../../services/SessionService";


export const OnlyGuestsRoute = ({children, redirectTo="/insurance", ...rest}) => {
    return (
        <Route
          {...rest}
          render={({ location }) => {
            if(!SessionService.isLoggedIn()){
              return children
            }else{
              return(
                <Redirect
                to={{
                  pathname: redirectTo,
                  state: { from: location }
                }}
              />)
            }
          }
          }
        />
      );
}