

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { IconButton } from '@material-ui/core';
import './SkyPopover.scss'
import { isMobile } from 'react-device-detect';
import FlexView from 'react-flexview/lib';
import { reportGTM } from '../Utilities/GTagManger';

var classNames = require('classnames')

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export const SkyElementHoverPopover = ({popOver, children, ...props}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover2' : undefined;

  return (
    <React.Fragment>
      <div onMouseEnter={handleClick} onMouseLeave={handleClose}>{children}</div>
      <Popover
        id={id}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
          <div className={classNames("sky-popver-container", {'black': !isMobile, 'white': isMobile})}>
            {popOver}
          </div>
      </Popover>
    </React.Fragment>)
}

export const SkyInformationPopover = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    reportGTM('','','popover-opened-' + props.eventId)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
        <HelpOutlineOutlinedIcon 
              fontSize="small" 
              style={props.style ? props.style : {}}
              onMouseEnter={handleClick} 
              onMouseLeave={handleClose} 
              onClick={handleClick} className="lightteal clickable margin-left-3" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        disableRestoreFocus
        PaperProps={{className: "sky-popover-paper"}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
          <div className={classNames("sky-popver-container", {'black': !isMobile, 'white': isMobile})}>
            {props.children}
          </div>
      </Popover>
      </React.Fragment>
  );
}