import { Grid, Link, Typography, useMediaQuery, withStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { BetterControlledTextField } from "../../Core/Form/ControlledFields";
import * as yup from 'yup';
import '../Login.scss'
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { OrangeButton } from "../../Core/Buttons/OrangeButton";
import { useHistory } from "react-router-dom";
import { SessionContextStore } from "../../../context/SessionContext/SessionContext";
import classNames from "classnames";
import { LoadingContextStore } from "../../../context/LoadingContext/LoadingContext";
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

const CustomLink = withStyles(theme => ({
    root: {
        marginTop: '10px',
        color: '#f67347',
        cursor: 'pointer'
    }
}))(Link);

export const userSchema = yup
.object()
  .shape({
    email: yup.string().required("Required").trim().email("Must be a valid email address"),
    password: yup.string().required("Required"),
})
.required();

const BrokerLogin = () => {
    const form = useForm({mode: 'onBlur', resolver: yupResolver(userSchema)});
    const history = useHistory()
    const {awaitWithLoading} = useContext(LoadingContextStore)
    const {login} = useContext(SessionContextStore)
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const onSubmitLoginRequest = async (data) => {
        await awaitWithLoading(login(data));
    }

    const handleForgetPassword = () => {
        history.push('/forgot-password')
    }
    
    return (
        <React.Fragment>
            <Grid container className="page">
                <Grid item xs={12}>
                    <div className={classNames("login-info-section", {"login-airplane-image": !isMobile, "login-airplane-image_mobile": isMobile })}>
                        <Grid item xs={12} sm={6} md={5} >
                            <div className='margin-left-basic-plus margin-top-medium'>
                                <div className='text-promotion bold white margin-bottom-basic title'><span className="light-blue">Broker</span> login</div>
                                <div className='text-body bold white margin-top-large'>login for partners</div>
                            </div>
                            <form className='margin-left-basic-plus margin-top-medium margin-right-basic-plus' autoComplete="off" onSubmit={form.handleSubmit(onSubmitLoginRequest)}>
                                <FormProvider {...form}>
                                    <Grid container>
                                        <Grid item xs={12} md={8}>
                                            <BetterControlledTextField fullWidth={true} name="email" className="margin-top-basic" placeholder="Email Address"/>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <BetterControlledTextField fullWidth={true} name="password" type="password" className="margin-top-basic style" placeholder="Password"/>
                                        </Grid>
                                        <Grid item xs={12} md={8} className="margin-top-6">
                                            <CustomLink onClick={handleForgetPassword}>Forgot password?</CustomLink>
                                        </Grid>
                                        <Grid item xs={12} md={8} className="margin-top-basic">
                                            <OrangeButton className={classNames("margin-0",{"mobile_button": isMobile})} type="submit">Login</OrangeButton>
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </form>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default BrokerLogin;