import { Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ControlledTextField } from '../Core/Form/ControlledFields';
import { QuoteContextStore } from '../../context/QuoteContext/QuoteContext';
import FlexView from 'react-flexview';
import SkywatchInformationTooltip from '../Core/Popover/SkywatchInformationTooltip';


export const PaymentPlan = () => {
  const methods = useFormContext();
  const [paymentPlan, setPaymentPlan] = useState('regular');
  const { price_breakdown, setInstallmentsType, installmentsType } = useContext(QuoteContextStore)
  const [installmentsList, setInstallmentsList] = useState([])
  const installmentsTypeWatch = methods.watch('installments')

  useEffect(() => {
    const storedValue = methods.watch('paymentPlan');
    if (storedValue) {
      setPaymentPlan(storedValue);
    }
  }, [methods]);

  useEffect(() => {
    setInstallmentsType(installmentsTypeWatch)
  },[installmentsTypeWatch])

  const updatePaymentPlan = (value) => {
    setPaymentPlan(value);
    methods.setValue('paymentPlan', value);
    if(value === 'regular') {
        methods.setValue('installments', 'default')
    }
  };

  useEffect(() => {
    if(!price_breakdown) return setInstallmentsList([]);
    let total = 0;
    total += price_breakdown.medical_coverage ?? 0
    total += price_breakdown.physical_damage_coverage ?? 0
    total += price_breakdown.liability_coverage ?? 0

    var instllmentsList = [{
      name: '2 installments',
      price: `of $${(total/2).toFixed(2)}` ,
      value: 'TwoMonth'
    },
    {
      name: '3 installments',
      price: `of $${(total/3).toFixed(2)}`,
      value: 'ThreeMonth'
    },
    {
      name: '4 installments',
      price: `of $${(total/4).toFixed(2)}`,
      value: 'FourMonth'
    }]

    setInstallmentsList(instllmentsList)
  },[price_breakdown])



  return (
    <Grid container  spacing={2}>
        <Grid item md={6} xs={12}>
        <Typography className='margin-bottom-small'>{'Choose payment plan'}</Typography>
        <Controller
        name="paymentPlan"
        control={methods.control}
        defaultValue="regular"
        as={
             <Grid container style={{width: '100%'}}>
              <Grid item xs={6}>
                  <button
                  type="button"
                  onClick={() => {
                      updatePaymentPlan('regular');
                  }}
                  style={{
                      backgroundColor: paymentPlan === 'regular' ? '#164076' : 'white',
                      color: paymentPlan === 'regular' ? 'white' : '#8898b5',
                      padding: '18px 20px',
                      cursor: 'pointer',
                      width: '100%',
                      border:' 1px solid #e9eef3',
                      borderRight: 'none',
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                      fontWeight: 'bold',
                      fontSize: '13px',
                      boxShadow: '0 4px 8px 0 rgba(44, 39, 56, 0.04)',
                  }}
                  >
                  Pay in full
                  </button>
              </Grid>
              <Grid item xs={6} >
                  <button
                  type="button"
                  onClick={() => {
                      updatePaymentPlan('installments');
                  }}
                  style={{
                      backgroundColor: paymentPlan === 'installments' ? '#164076' : 'white',
                      color: paymentPlan === 'installments' ? 'white' : '#8898b5',
                      border:' 1px solid #e9eef3',
                      borderLeft: 'none',
                      padding: '18px 20px',
                      cursor: 'pointer',
                      width: '100%',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                      fontWeight: 'bold',
                      fontSize: '13px',
                      boxShadow: '4px 0 8px 0 #f5f5f5',
                  }}
                  >
                  Installments
                  </button>
              </Grid>
            </Grid>
        }
        />
        </Grid>
        {paymentPlan === 'installments' && 
            <Grid item md={6} xs={12}>
            <SkywatchInformationTooltip className='margin-bottom-small' text="Split your payment into up to four equal installments. Initial payment includes a $20 fee." placement="bottom">
                <Typography variant="h4" className="grey">Installments</Typography>
            </SkywatchInformationTooltip>
            <ControlledTextField
                    select
                    fullWidth={true}
                    name="installments"
                    defaultValue={ installmentsType || "default"}
                >
                    <MenuItem selected value="default" className="gray">
                        <span className="placeholder-gray">Installments</span>
                    </MenuItem>
                    {installmentsList.map((item, i) => 
                      <MenuItem key={i} value={item.value}>
                        <Grid container justifyContent='space-between' className='bold text-small' style={{color: '#445870'}}>
                          <Grid item> <div>{item.name}</div></Grid>
                          <Grid item> <div>{item.price}</div></Grid>
                        </Grid>
                      </MenuItem>)}
                </ControlledTextField>
            </Grid>
        }
    </Grid>
  );
};
