import { Grid, makeStyles, Tooltip, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { ReactComponent as InfoIcon} from '../../../assets/info-icon.svg';

const useStyle = makeStyles(theme => ({
    root: {
        padding: '0 10px'
    },
    icon: {
        
    },
    text: {
        padding: '5px 0px'
    }
}))

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ddeff5',
        color: '#164076',
        fontFamily: 'Poppins-regular',
        fontSize: 14,
        borderRadius: 8,
        // minWidth: '100%'
    },
    arrow: {
        color: '#ddeff5',
    }
}))(Tooltip);




export const SkywatchTooltip = ({text, IconComponent, children, subtext, showSubtext = false, ...props}) => {
    const classes = useStyle();

    const tooltipStyle = {
        paddingLeft: IconComponent && 10,
        paddingRight: IconComponent && 10,
    }

    return <CustomTooltip {...props} arrow title={
                <Grid className={classes.root} container wrap='nowrap' alignItems='center'>
                    <Grid className={IconComponent ? "margin-right-6" : ""}>
                        { IconComponent && <IconComponent className={classes.icon} /> }
                    </Grid>
                    <Grid container direction='column' className={classes.text} style={tooltipStyle}>
                        {showSubtext && subtext && <Grid item>
                            <Typography variant="body1" className="blue bold">
                                {subtext}
                            </Typography>
                        </Grid>}
                        <Typography variant='body'>
                                {text}
                        </Typography>
                    </Grid>
                </Grid>
                }>
                {children}
            </CustomTooltip>
}

const SkywatchInformationTooltip = ({children, text, IconComponent, ...props}) => {
    const [open, setOpen] = useState(false);    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const tooltipProps = {
        ...props,
        placement: isMobile ? "bottom" : props.placement,
        onClick: isMobile ? (() => {
            setOpen(prev => !prev)
        }) : undefined
    }

    return <Grid container wrap='nowrap' alignItems='center'>
                {!isMobile && children}
                <SkywatchTooltip showSubtext={isMobile} open={open} {...tooltipProps} text={text} IconComponent={IconComponent}>
                    <span className='display-flex-center'>
                        {isMobile && children}
                        <InfoIcon onMouseEnter={handleOpen} onMouseLeave={handleClose} className='margin-left-5'/>
                    </span>
                </SkywatchTooltip>
            </Grid>
}

export default SkywatchInformationTooltip;