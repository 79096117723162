import ApiService, { HTTPMethod } from "./api.core";
import dayjs from "dayjs";
import { SessionService } from '../services/SessionService';


class LoginModel {
    token: string;
    // expiration: Date;
  
    constructor(json: any) {
      this.token = json.token;
      // this.expiration = new Date(json.expiration);
    }
}  

export class AuthApi {

    static async login(email, password): Promise<ApiResponseModel> {
        let resp = await ApiService.fetch("auth/token", HTTPMethod.POST, {
          email: email,
          password: password
        });
        if (resp.ok) {
          resp.parsedData = new LoginModel(resp.data);
        }
        return resp;
    }

    static async passwordlessLogin(email, lastname, zip): Promise<ApiResponseModel> {
      let resp = await ApiService.fetch("auth/passwordless_login", HTTPMethod.POST, {
        email: email,
        last_name: lastname,
        zip_code: zip
      });
      if (resp.ok) {
        resp.parsedData = new LoginModel(resp.data);
      }
      return resp;
  }

    static async logout() {
        return await ApiService.fetch("auth/logout");
    }

    static async socialLogin(email, token, serviceName) {
      let resp = await ApiService.fetch(`auth/${serviceName}/token`, HTTPMethod.POST, {
        access_token: token
      })
      resp.parsedData = resp.data;
      return resp
    }

    static async register(email, password) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let resp = await ApiService.fetch("auth/register", HTTPMethod.POST, {
        email: email,
        password: password,
        utm_campaign: params.utm_campaign,
        utm_medium: params.utm_medium,
        utm_source: params.utm_source,
      });
      resp.parsedData = resp.data;
      return resp
    }

    static async registerPasswordless(email, zipCode, state) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let resp = await ApiService.fetch("auth/register-passwordless", HTTPMethod.POST, {
        email: email,
        utm_campaign: params.utm_campaign,
        utm_medium: params.utm_medium,
        utm_source: params.utm_source,
        zipcode: zipCode,
        administrative_division: state
      });
      return resp
    }

    static async sendForgotPasswordEmail(email) {
      return ApiService.fetch("auth/forgot_password", HTTPMethod.POST, {
        email: email
      })
    }

    static async getProfile() {
      return ApiService.fetch("profile/getProfile", HTTPMethod.GET)
    }

    static async getDeeplink({email}) {
      return await ApiService.fetch("auth/deeplink-login", HTTPMethod.POST, {email});
    }

    static async validateDeeplink(data) {
      const res = await ApiService.fetch("auth/validate-deeplink-token", HTTPMethod.POST, data);
      return res;
    }
}