
import React, { useContext, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, withStyles } from "@material-ui/core";
import { ReactComponent as AccountSvg } from '../../../assets/sidemenu/login.svg'
import AdminBox from '../AdminBox';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { BlueButton } from "../../Core/Buttons/BlueButton";
import { OrangeButton } from "../../Core/Buttons/OrangeButton";
import { ControlledDatePicker } from "../../Core/Form/Datepickers";
import adminApi from "../../../api/admin.api";
import { LoadingContextStore } from '../../../context/LoadingContext/LoadingContext';

const CustomDialog = withStyles(theme => ({
    paper: {
        width: '100%',
        padding: '10px'
    }
}))(Dialog);

export const RunBordereauxSchema = yup
.object()
  .shape({
    from: yup.date().required("Required"),
    to: yup.date().required("Required")
})
.required();

export default () => {
    const [openDialog, setOpenDialog] = useState(false);
    const {awaitWithLoading} = useContext(LoadingContextStore)
    const form = useForm({mode: 'onSubmit', resolver: yupResolver(RunBordereauxSchema)});

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleOpen = () => {
        setOpenDialog(true);
    }

    const onSubmit = async (data) => {
        const res = await awaitWithLoading(adminApi.runBordereaux(data))
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(res.data)
        link.download = 'bordereaux.csv'
        link.click()
        link.remove()
    }

    return <AdminBox Icon={AccountSvg} title="Reporting">
        <Button className="no-text-transform" variant="text">
            <Typography variant="body2" onClick={handleOpen}>Run Bordereaux</Typography>
        </Button>

        <CustomDialog maxWidth={'sm'} open={openDialog} onClose={handleClose}>
            <form className="margin-top-8" onSubmit={form.handleSubmit(onSubmit)}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  <Typography variant="h3" className="blue bold">Run Bordereaux</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body2" className="margin-bottom-6">Insert date range</Typography>
                        <FormProvider {...form}>
                            <Grid container spacing="2">
                                <Grid item>
                                    <Typography>From</Typography>
                                    <ControlledDatePicker name="from" openTo="date"/>
                                </Grid>
                                <Grid item>
                                <Typography>To</Typography>

                                    <ControlledDatePicker name="to" openTo="date"/>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <OrangeButton type="submit">
                        Run
                    </OrangeButton>
                    <BlueButton onClick={handleClose}>
                        Cancel
                    </BlueButton>
                </DialogActions>
            </form>
        </CustomDialog>
    </AdminBox>
}