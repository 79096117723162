import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl } from "../services/utils";

class AircraftApi {
    async getYears(make, model, year) {
        return ApiService.fetch("aircraft/years", HTTPMethod.POST, {
          make,
          model,
          year
        });
      }

    async getModels(make, model, year) {
        return ApiService.fetch("aircraft/models", HTTPMethod.POST, {
          make,
          model,
          year
        });
    }

    async getMakes(make, model, year) {
      return ApiService.fetch("aircraft/makes", HTTPMethod.POST,{
        year,
        model,
        make
      });
    }
    async getAircraft(make, model, year) {
      let urlEncoded = buildQueryParamsUrl("aircraft", {
        year,
        model,
        make
      });
      return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }
    async saveAircraftPage({ quote_id, make,model, year, value, total_seats, n_number, is_hangared, aircraft_id, allow_hull, make_model_not_found}){
      value = value === "" ? 0 : value
      return ApiService.fetch(`aircraft/${quote_id}`, HTTPMethod.POST, {
        make,
        model,
        year: year.toString(),
        seats: total_seats,
        value,
        n_number,
        is_hangared,
        aircraft_id,
        make_model_not_found,
        is_hull_covered: allow_hull
      });
    }

    async goBack(quote_id){
      return ApiService.fetch(`aircraft/back/${quote_id}`, HTTPMethod.POST);
    }

    async saveNNumberToExistingAircraft({quote_id,aircraft_id,n_number}){
      return ApiService.fetch(`aircraft/add-nnumber-to-aircraft/${quote_id}/${aircraft_id}`, HTTPMethod.POST, n_number);
    }
}

export default new AircraftApi();
