import React, { useContext } from 'react';
import { createContext, useReducer,useEffect } from "react";
import QUOTE_ACTIONS, { useActions } from "./actions";
import { isToday,prettyLimitPrice} from "../../services/utils";
import StorageService from '../../services/StorageService';
import { useLocation, useHistory } from 'react-router-dom';
import { SessionContextStore } from '../SessionContext/SessionContext';
import dayjs from 'dayjs';


const titles = {
    profile: "Aircraft Owners Insurance",
    aircraft: "Aircraft Details",
    airport: "Airport Details",
    pilot: "Pilot Details",
    coverages: "Customize Coverages",
    summary: "Summary",
    checkout: "Checkout"
}

const defaultState = {
    title: "Aircraft Owners Insurance",
    profile: null,
    availableMemberships :[],
    memberships:[],
    aircrafts: {
        0: {
            n_number: "",
            year: "",
            make: "",
            model: "",
            min_value: 0,
            max_value: 550000,
            is_hangared: "false",
            allow_hull: true
        }
    },
    airport_authority: {
            state: "default",
            city: "",
            address_line1: "",
            address_line2: "",
            zip: "",
            name: "",
    },
    airports: {
        0: {
            code: "",
            name: "",
            city: "",
            state: "default",
            runway_length: "",
            runway_elevation: "",
            runway_surface: "default",
            airport_not_found: false
        }
    },
    airportSearchMode: "code",
    pilots: null,
    currentPilot: null,
    coverages: {
        options: null,
        selected: {
            liability_coverage: "default",
            physical_damage_coverage: "default",
            medical_coverage: null
        }
    },
    price_breakdown: null,
    clientSecret: StorageService.getItem("clientSecret"),
    start_date: dayjs(new Date()).format(),
    end_date: dayjs(new Date()).format(),
    is_approved: false,
    airport_authority: null,
    add_airport_authority: false,
    currentStep: 0,
    total_surcharge: null,
    total_amount: null,
    quoteLoaded: false,
    status: "Application",
    shouldShowPriceWidgit: false,
    shouldShowMedicalCoverage : true,
    installmentsType: null,
    installmentsFee: 20
}


const calculateState = (state, action) => {
    switch (action.type) {
        case QUOTE_ACTIONS.GO_BACK:
        case QUOTE_ACTIONS.SET_STEP:
            return { ...state, currentStep: action.payload.step, title: titles[action.payload.title], shouldShowPriceWidgit: action.payload.shouldShowPriceWidgit};
        case QUOTE_ACTIONS.LOAD_STATE:
            return { ...state, ...action.payload };
        case QUOTE_ACTIONS.REFRESH_MEMBERSHIPS:
            return {...state, memberships: action.payload.data}
        case QUOTE_ACTIONS.LOAD_AVAILABLE_MEMBERSHIPS:
            return {...state, availableMemberships:action.payload.data}
        case QUOTE_ACTIONS.FINISH_PROFILE:
            return { ...state,profile: action.payload.profile, quote_id: action.payload.quote_id, quoteLoaded: true, currentStep: action.payload.currentStep,aircrafts:action.payload.aircrafts, title:  titles["aircraft"]}
        case QUOTE_ACTIONS.LOAD_AIRCRAFT: 
            const { aircrafts } = state;
            const defaultAircraftId = Object.keys(aircrafts)[0];
            delete action.payload.allow_hull;
            const payload = {...aircrafts[Object.keys(aircrafts)[0]], ...action.payload, year: action.payload.year.toString()}
            return { ...state, aircrafts: { ...state.aircrafts, [defaultAircraftId]: payload}}
        case QUOTE_ACTIONS.LOAD_AIRPORT:
            const { airports } = state;
            const defaultAirportId = Object.keys(airports)[0];
            return { ...state, airports: { ...state.airports, [defaultAirportId]: action.payload}}
        case QUOTE_ACTIONS.CHANGE_AIRPORT_MODE:
            return { ...state, airportSearchMode: action.payload}
        case QUOTE_ACTIONS.FINISH_AIRCRAFTS:
            return { ...state, profile: action.payload.profile, aircrafts: {...action.payload.aircrafts}, currentStep: action.payload.currentStep, title: titles["airport"] }
        case QUOTE_ACTIONS.ADD_PILOT:
            return { ...state, pilots: action.payload.pilots}
        case QUOTE_ACTIONS.DELETE_PILOT:
            const newState = state.pilots;
            delete newState[action.payload.pilotId]
            return { ...state, pilots: {...newState}}
        case QUOTE_ACTIONS.SET_CURRENT_PILOT: 
            return {...state, currentPilot: action.payload }
        case QUOTE_ACTIONS.RESET_CURRENT_PILOT: 
            return {...state, currentPilot: null }
        case QUOTE_ACTIONS.EDIT_PILOT:
            return { ...state, currentPilot: !!action.payload.nextPilotId ? action.payload.nextPilotId: state.currentPilot, pilots: {...state.pilots, [state.currentPilot]: {...action.payload.pilot}} }
        case QUOTE_ACTIONS.FINISH_AIRPORTS:
            return { ...state,
                    aircrafts: {...action.payload.aircrafts},
                    airports: {...action.payload.airports},
                    currentStep: action.payload.currentStep,
                    title: titles["pilot"]  }
        case QUOTE_ACTIONS.FINISH_PILOTS:
            return { ...state, ...action.payload, title: titles["coverages"] }
        case QUOTE_ACTIONS.LOAD_OPTIONS:
            const {coverages, shouldResetLiability, shouldResetHull, shouldShowPriceWidgit} = action.payload;
            return {
                ...state,
                shouldShowPriceWidgit,
                status: action.payload.status,
                coverages: {
                    ...state.coverages,
                    options: coverages.options,
                    selected: {
                        ...state.coverages.selected,
                        medical_coverage: coverages.options.medical_coverages[Object.keys(coverages.options.medical_coverages)[0]].limit,
                        liability_coverage: !shouldResetLiability ? state.coverages.selected.liability_coverage : "default",
                        physical_damage_coverage: !shouldResetHull ? state.coverages.selected.physical_damage_coverage : "default"
                    }
                }
            };
        case QUOTE_ACTIONS.CHANGE_STARTING_DATE:
            return {...state, start_date: action.payload}
        case QUOTE_ACTIONS.LIABILITY_COVERAGES_CHANGED:
            return {...state, 
                shouldShowMedicalCoverage : action.payload.shouldShowMedical,
                coverages:{
                    ...state.coverages,
                    selected: { ...state.coverages.selected,
                        liability_coverage: action.payload.key
                    }
                },
            }        
        case QUOTE_ACTIONS.HULL_COVERAGES_CHANGED:
            return {...state, 
                coverages:{
                    ...state.coverages,
                    selected: {
                        ...state.coverages.selected,
                        physical_damage_coverage: action.payload.key,
                    }
                },
            }
        case QUOTE_ACTIONS.MEDICAL_COVERAGES_CHANGED:
            return {...state, 
                coverages:{
                    ...state.coverages,
                    selected: {
                        ...state.coverages.selected,
                        medical_coverage: action.payload.option,
                    }
                },
            }
        case QUOTE_ACTIONS.PRICE_BREAKDOWN_CHANGED:
            return {...state, price_breakdown: action.payload}
        case QUOTE_ACTIONS.FINISH_COVERAGES:
            return { 
                ...state,
                total_amount: action.payload.data.total_amount,
                total_premium: action.payload.data.total_premium,
                total_surcharge: action.payload.data.total_surcharge,
                currentStep: action.payload.currentStep,
                coverages: { options: { ...state.coverages.options }, selected: { ...state.coverages.selected }, airport_authority: state.coverages.airport_authority },
                title: titles["summary"],
                start_date: new Date(action.payload.data.start_date),
                end_date: new Date(action.payload.data.end_date),
                airport_authority: {...action.payload.airport_authority},
                status: action.payload.status
                }
        case QUOTE_ACTIONS.FINISH_SUMMARY:
            return { ...state, currentStep: 4, title:  titles["checkout"], shouldShowPriceWidgit: true, ...action.payload}
        case QUOTE_ACTIONS.FINISH_UNKNOWN_NNUMBER_POPUP:
            return {...state,aircrafts: {...action.payload.aircrafts}}
        case QUOTE_ACTIONS.CLIENT_SECRET:
            return { ...state, clientSecret: action.payload}
        case QUOTE_ACTIONS.LOAD_QUOTE: 
            return { ...state, ...action.payload, quoteLoaded: true}
        case QUOTE_ACTIONS.SET_LOGGED_USER:
            return { ...state, profile: { ...state.profile, email: action.payload }}
        case QUOTE_ACTIONS.UPDATE_INSTALLMENTS_TYPE:
            return { ...state, installmentsType: action.payload}
        default: 
            return state;
    }
}

const stateToLocalStorage = (state) => {
    let savedStatte = Object.assign({}, state)
    delete savedStatte.offerCardErrors
    delete savedStatte.signature
}

const reducer = (state, action) => {
    let newState = calculateState(state, action)
    stateToLocalStorage(newState)
    return newState
};

export const QuoteContextStore = createContext(defaultState);

const QuoteContext = ({ children }) => {
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, defaultState);
    const actions = useActions(dispatch, state);

    useEffect(() => {
        actions.setStep();
    }, [location])


    useEffect(()=>{
        if(state.coverages.selected.physical_damage_coverage !== 'default' || state.coverages.selected.liability_coverage !== 'default' || state.coverages.selected.medical_coverage ){
            actions.loadPriceBreakdown(state.coverages.selected)
        }
    },[state.coverages.selected])

    return <QuoteContextStore.Provider value={{ ...state, dispatch, ...actions}}>
        {children}
    </QuoteContextStore.Provider>
} 

export const useQuote = () => useContext(QuoteContextStore);

export default QuoteContext;
