import { Checkbox, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useFormContext } from 'react-hook-form';
import { QuoteContextStore } from '../../../context/QuoteContext/QuoteContext';

export const ApplicantPilotCheckbox = () => {
    const [checked, setChecked] = useState(true);
    const { setValue } = useFormContext()
    const { profile, pilots } = useContext(QuoteContextStore);
    const isFirstPilot = !pilots || Object.keys(pilots).length === 0;

    const handleChange = (e) => {
        const { checked: flag } = e.target;
        setChecked(flag);
        if(flag) {
            setValue("first_name", profile.name)
            setValue("last_name", profile.surname)
        } else {
            setValue("first_name", "")
            setValue("last_name", "")
        }
    };

    return (
        isFirstPilot && profile?.applicant_type === 'Individual' && <Grid item xs={12} md={12}>
            <FlexView vAlignContent={'center'} className="gray">
                <div>
                    <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <div>I am the pilot</div>
            </FlexView>
        </Grid>
    );
};
