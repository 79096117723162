import { LiabilityExtension } from "./LiabilityExtension"
import { PhysicalDamageExtension } from "./PhysicalDamageExtension"
import { InsuranceStatus } from "./InsuranceStatus"
import { AdditionalInsuredModel } from "../user-models/AdditionalInsuredModel"
import { AdditionalInsuredEndorsementExtension } from "./AdditionalInsuredEndorsementExtension"
import { Operator } from "./Operator"
import { InsuranceTypes } from "./InsuranceBillingPeriodModel"
import { ActiveInsuranceSubscription } from './ActiveInsuranceSubscription'
import { Vehicle } from "../hull-models/Vehicle"

export class ActiveInsuranceModel {
    _certificateUrl: string
    policyUrl: string
    insuranceId: string
    summary: ?string[]
    
    timeLeftSeconds: number
    autoRenew: boolean
    renewable: boolean
    policyNumber: number
    _liabilityLimit: number
    liabilityLimitPremium: number
    liabilityLimitDiscount: number
    locationDescription: string
    _insuranceEnd: Date
    _insuranceStart: Date
    dateAdded: Date
    insuranceType: string
    additionalInsuredList: AdditionalInsuredModel[]
    finalPrice: number
    liabilityLimitExtensions: LiabilityExtension[]
    physicalDamageExtensions: PhysicalDamageExtension[]
    additionalInsuredExtensions: AdditionalInsuredEndorsementExtension[]
    isSuspended: Boolean
    isSuspendable: Boolean
    isActivated: Beoolean
    suspensionStartTime: Date
    suspensionEndTime: Date
    operators: Operator[]
    subscription: ActiveInsuranceSubscription
    insuredVehicles: Vehicle[]
    vehicleCoverages: any[]
    policyCoverages: any[]
    fixedPrice: number
    perMilePrice: number
    estimatedFinalPrice: number
    fees: number
    taxes: number
    taxesMultiplier: number
    
    


    get certificateUrl() {
      return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].certificateUrl : this._certificateUrl
    }

    set certificateUrl(val) {
      this._certificateUrl = val
    }

    get liabilityLimit() {   
      return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].liabilityLimit : this._liabilityLimit
    }

    get totalLiabilityPremium() {
      return this.liabilityLimitPremium + (this.hasLiabilityExtentions()? this.liabilityLimitExtensions.reduce((res, ext) => res + ext.purchaseDetails.price, 0) : 0);
    }

    set liabilityLimit(val) {
      this._liabilityLimit = val
    }
  
    set insuranceStart(val) {
      this._insuranceStart = val
    }
  
    get insuranceStart(): Date {
      return this._insuranceStart;
      // return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].validFromUtc : this._insuranceStart
    }
  
    set insuranceEnd(val) {
      this._insuranceEnd = val
    }
  
    get insuranceEnd(): Date {
      return this._insuranceEnd;
      // return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].validUntilUtc : this._insuranceEnd
    }
  
    isSubscription() {
      return this.insuranceType == InsuranceTypes.MONTHLY || this.insuranceType == InsuranceTypes.YEARLY
    }
  
    hasLiabilityExtentions() {
      return this.liabilityLimitExtensions && this.liabilityLimitExtensions.length > 0
    }

    isActive() {
      return this.insuranceEnd > new Date() && this.insuranceStart < new Date() && this.isActivated
    }

    isPending() {
      return this.insuranceEnd > new Date() && this.insuranceStart < new Date() && !this.isActivated
    }

    isScheduled() {
      return this.insuranceEnd > new Date() && this.insuranceStart > new Date()
    }

    isActiveOrSchedule() {
      return this.isActive() || this.isScheduled() || this.isSuspended
    }

    isActiveOrExpired() {
      return this.isActive() || this.getStatus() === InsuranceStatus.INACTIVE 
    }

    getStatus() {
      if (this.isActive())
        return InsuranceStatus.ACTIVE
      else if (this.isPending())
        return InsuranceStatus.PENDING
      else if (this.isScheduled()) 
        return InsuranceStatus.SCHEDULED
      else if (this.isSuspended)
        return InsuranceStatus.SUSPENDED
      else
        return InsuranceStatus.INACTIVE
    }

    getCoverageConfiguration() {
      let configuration = {
        vehicle_requests: {}
      }
      Object.keys(this.policyCoverages).forEach(key => {
        configuration[key] = this.policyCoverages[key];
      })
      Object.keys(this.vehicleCoverages).forEach(vehicleId => {
        configuration.vehicle_requests[vehicleId] = {"coverages": {}}
        Object.keys(this.vehicleCoverages[vehicleId]).forEach(vehicleCoverageKey => {
          configuration.vehicle_requests[vehicleId]["coverages"][vehicleCoverageKey] = this.vehicleCoverages[vehicleId][vehicleCoverageKey];
        })
      })
      return configuration;
    }
  } 