import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl, KPretty } from '../services/utils';
import { Transformer } from "../models/Transformer";
import dayjs from "dayjs";

class SummaryApi {
    async goBack(quoteId){
        return ApiService.fetch(`summary/back/${quoteId}`, HTTPMethod.POST);
    }

    async saveSummary(quoteId, data) {
        let res = await ApiService.fetch(`summary/finish-summary/${quoteId}`, HTTPMethod.POST, data);
        return res;
    }

    async downloadQuote(quoteId) {
        let res = await ApiService.fetch(`summary/download/${quoteId}`, HTTPMethod.POST);
        return res;
    }
}

export default new SummaryApi()