import React, { useContext } from 'react';
import paymentApi from '../../api/payment.api';
import { AlertContextStore } from '../AlertContext/AlertContext';
import { LoadingContextStore } from '../LoadingContext/LoadingContext';

const PAYMENT_ACTIONS = {
    LOAD_CLIENT_SECRET: "LOAD_CLIENT_SECRET",
    ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
    SET_CHARGE_FLAG: "SET_CHARGE_FLAG",
    GET_ACTIVE_PAYMENT_METHOD: "GET_ACTIVE_PAYMENT_METHOD",
}

export const useActions = (dispatch, state) => {
    const { awaitWithLoading } = useContext(LoadingContextStore);
    const { showServerError } = useContext(AlertContextStore);

    return {
        loadClientSecret: async function() {
            const secret = await paymentApi.getClientSecret();
            dispatch({
                type: PAYMENT_ACTIONS.LOAD_CLIENT_SECRET,
                payload: secret
            })
        },
        addPaymentMethod: async function(data) {
            const res = await awaitWithLoading(await paymentApi.addPaymentMethod({
                ...data
            }));
            
            if(!res.ok) showServerError({message: res.message});
            
            return res;
        },
        getActivePaymentMethod: async function() {
            const res = await awaitWithLoading(await paymentApi.getActivePaymentMethod());
            
            if(!res.ok) showServerError({message: res.message});

            dispatch({
                type: PAYMENT_ACTIONS.GET_ACTIVE_PAYMENT_METHOD,
                payload: res.data
            })
        }
    }
}

export default PAYMENT_ACTIONS;