import React, { useState, useContext, useEffect, useMemo } from "react";
import { Paper, Grid, ButtonBase, Typography, Link, Divider, Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import { Apple } from "@material-ui/icons";
import CreditCardPayment from "./PaymentMethods/CreditCardPayment";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PAYMENT_METHODS } from "../../Constants";
import { isInFlow } from "../../services/utils";
import { GooglePayIcon } from "../Core/Icons/PaymentIcons.js";
import { makeStyles } from "@material-ui/styles";
import { SessionService } from "../../services/SessionService.js";
import { useLocation } from 'react-router-dom';
import WalletPayment from "./PaymentMethods/WalletPayment";
import { ReactComponent as DebitIcon} from '../../assets/credit-cards.svg';
import { PaymentContextStore } from "../../context/PaymentContext/PaymentContext";
import { QuoteContextStore } from '../../context/QuoteContext/QuoteContext';
import SkywatchFormPage from "../Core/Popover/SkywatchFormPage";
import TermsAndConditions from "../Core/Bureaucracy/TermsAndConditions";
import TriaDisclaimer from "../Core/Bureaucracy/TriaDisclaimer";
import SkywatchDivider from "../Core/Dividers/SkywatchDivider";
import SkywatchLink from "../Core/Links/SkywatchLink";
import { reportGTM } from "../Core/Utilities/GTagManger";
import { SessionContextStore } from "../../context/SessionContext/SessionContext";
import { PaymentPlan } from "./PaymentPlan";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { paymentPlanSchema } from "./schemas/paymentPlanSchema";
import { getDueTodayInstallmentPayment } from "./installments";

const usePaperStyle = makeStyles((theme) => ({
  root: {
    "&.checked": {
      border: `1px solid ${theme.palette.grey.light}`,
      backgroundColor: '#dfe3ef',
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const useRippleStyle = makeStyles((theme) => ({
  root: {
    "&": {
      width: "100%",
      display: "block",
    },
  },
}));

const PaymentIcon = ({ paymentMethod }) => {
  switch (paymentMethod) {
    case "credit":
      return <DebitIcon />;
    case "applePay":
      return <Apple />;
    case "googlePay":
      return <GooglePayIcon />;
    default:
      return <GooglePayIcon />;
  }
};

const PaymentMethods = ({ onPurchase,inFlow }) => {
  const { addPaymentMethod, setFlag, shouldCharge } = useContext(PaymentContextStore);
  const { price_breakdown, installmentsType, installmentsFee } = useContext(QuoteContextStore);
  const { user } = useContext(SessionContextStore);
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation()
  const paperClasses = usePaperStyle();
  const rippleClasses = useRippleStyle();
  const [wallet, setWallet] = useState();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("credit");
  const [availableMethods, setAvailableMethods] = useState({ credit: true });
  const [openTermsPopUp, setOpenTermsPopUp] = useState(false);
  const form = useForm({resolver: yupResolver(paymentPlanSchema)});
  const [editMode, setEditMode] = useState(true)

  const totalPrice = useMemo(() => {
    if(!price_breakdown) return 0;
    let total = 0;
    total += price_breakdown.physical_damage_coverage ?? 0
    total += price_breakdown.liability_coverage ?? 0
    return total
  }, [price_breakdown])

  useEffect(() => {
    if (wallet) setAvailableMethods({ ...availableMethods, [wallet]: true });
  }, [wallet]);

  const getTotalPriceToPay = () => {
    let total
    if(!!installmentsType){
      const res =  getDueTodayInstallmentPayment(totalPrice, installmentsType, installmentsFee) 
      total = parseInt(parseFloat(res) * 100);
    }else{
      total = parseInt(parseFloat(totalPrice) * 100);
    }
    return total;
     
  }

  useEffect(() => {
    if (!stripe || !elements || !totalPrice) return;
    if (SessionService.isBroker()) return;

    if (!isInFlow(location.pathname)) return;

    let total = getTotalPriceToPay();

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Total",
        amount: total,
      },
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (!result) return;
      setPaymentRequest(pr);
      setWallet(
        result.googlePay ? "googlePay" : result.applePay ? "applePay" : null
      );
    });
  }, [stripe, elements, totalPrice, installmentsType]);

  useEffect(() => {
    if (!paymentRequest) return;
    paymentRequest.on("paymentmethod", async (e) => {
      let method = "Google/Apple"
      switch (e.walletName) {
        case 'googlePay':
          method = 'Google-Pay'
          break;
        case 'applePay':
          method = 'Apple-Pay'
          break;
      }
      let response = await addPaymentMethod({ payment_method_type: method, payment_method_token: e.paymentMethod.id });
      if (response.ok) {
        e.complete('success');
        purchase();
        return;
      }
      e.complete('fail');
    });
  }, [paymentRequest]);

  const handleChangePaymentMethod = async (e, method) =>{
    setSelectedPaymentMethod(method);
    const isFormValid = await validateForm(e)
    if(!isFormValid) return
    reportGTM({'event': 'select-'+ method.toLowerCase(), userEmail: user.email, at: window.location.pathname})
    methodToFunction(selectedPaymentMethod);
    setFlag(false)
  }

  const purchase = () => {
    const values = form.getValues()
    let paymentType;
    if(values.paymentPlan === 'regular'){
      paymentType = 'PayInFull'
    }else{
      paymentType = values.installments
    }
    onPurchase(paymentType)
  }

  const renderPaymentMethod = () => {
      switch (selectedPaymentMethod) {
        case "googlePay":
        case "applePay":
            return <WalletPayment methodOfPayment={selectedPaymentMethod}/>
        case "credit":
            return <CreditCardPayment stripe={stripe} elements={elements} onPurchase={purchase} editMode={editMode} setEditMode={setEditMode} />
          default:
              break;
      }
  }

  const methodToFunction = (method)=> {
    if(!paymentRequest) return;
    switch (method) {
      case "applePay":
      case "googlePay":
        paymentRequest.show();
        break;
      default:
        break;
    }
  }

  const validateForm = async (e) => {
      e.preventDefault()
      const isValid = await form.trigger()
      if(isValid || !inFlow) {
        //Everythunig is ok
        setFlag(true)
        return true
      } else{ 
        // Errors
        setFlag(false)
        return false
      }
  
  } 


  return (
    <Grid container>
      <Grid container item xs={12} sm={7} spacing={1} className="margin-bottom-basic">
        {Object.keys(availableMethods).map((method) => {
          const hideMethod = !availableMethods[method];
          return (
            <Grid
              item
              xs={4}
              sm={3}
              key={method}
              hidden={hideMethod}
              onClick={(e) => handleChangePaymentMethod(e, method)}
            >
              <ButtonBase
                className={rippleClasses.root}
              >
                <Paper
                  className={`${method === selectedPaymentMethod ? "checked" : ""} ${
                    paperClasses.root
                  } padding-top-10 padding-bottom-10`}
                  elevation={0}
                  variant="outlined"
                  key={method}
                >
                  <Grid container justifyContent="center">
                    <PaymentIcon paymentMethod={method} />
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    className="margin-top-6"
                  >
                    <span className={`${method === selectedPaymentMethod ? "blue" : "gray"} bold text-small`}>
                      {PAYMENT_METHODS[method].name}
                    </span>
                  </Grid>
                </Paper>
              </ButtonBase>
            </Grid>
          );
        })}
      </Grid>
      <Grid container>
        <Grid item xs={12} className='margin-bottom-basic'>
          <FormProvider {...form}>
          <form id="checkout" onSubmit={e => validateForm(e)}  autoComplete="off" >
            {inFlow && <PaymentPlan />}
          </form>
        </FormProvider>
        </Grid>
        {renderPaymentMethod()}
        {isInFlow(location.pathname) && 
        <Grid className="margin-top-basic">
            <Typography variant="body2">It is recommended you view the entire <SkywatchLink href="https://skywatchapp-prod.s3.amazonaws.com/common_files/global_sample_owners_policy.pdf" target="_blank" className="secondary">sample policy</SkywatchLink> before purchasing. By clicking “Complete Purchase” you agree to the <SkywatchLink onClick={() => setOpenTermsPopUp(true)}>terms and conditions</SkywatchLink> shown below.</Typography>
        </Grid>}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SkywatchDivider/>
        </Grid>
      </Grid>
      <Grid container>
          <Grid item xs={12}>
            {isInFlow(location.pathname) ? <TriaDisclaimer/> : <div></div>}
        </Grid>
      </Grid>
      <SkywatchFormPage open={openTermsPopUp} handleOpen={() => setOpenTermsPopUp(true)} handleClose={() => setOpenTermsPopUp(false)}>
        <Typography variant="h1" className="blue">Terms and Conditions</Typography>
        <TermsAndConditions />
      </SkywatchFormPage>
    </Grid>
  );
};

export default PaymentMethods;
