import React, {useContext, useEffect, useState} from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { AlertContextStore } from '../../../context/AlertContext/AlertContext'
import { SessionContextStore } from '../../../context/SessionContext/SessionContext'
import { QuoteContextStore } from '../../../context/QuoteContext/QuoteContext';
import { awaitWithLoading } from "../../../services/utils";

const REDIRECT_STATES = {
    PROCESSING: 'PROCESSING',
    RESOLVED: 'RESOLVED',
}

export const EntranceProtectedRoute = ({ children, ...rest }) => {
  const {loadQuote, pilots, setCurrentPilot} = useContext(QuoteContextStore);
  const location = useLocation()
  const {validateDeeplink, loadUser} = useContext(SessionContextStore)
  const [status, setStatus] = useState(REDIRECT_STATES.PROCESSING);
  const { showError } = useContext(AlertContextStore);
  const [routeParams, setRouteParams] = useState({})
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const profile = params.get("profile");
    const deeplinkToken = params.get("deeplinkToken");
    const quoteId = params.get("quoteId");
    params.delete('profile')
    params.delete('deeplinkToken')
    params.delete('quoteId')
    if(!(profile && deeplinkToken))
    {
        setStatus(REDIRECT_STATES.ERROR)
        setRouteParams({
            pathname: '/insurance/profile',
        })
    } 
    else 
    {
        validateDeeplink({deeplinkToken, profile}).then(async (res) => {
            const {ok, data} = res;
            if(ok) {
                await loadUser();
                if(!!quoteId){
                  await awaitWithLoading(loadQuote(quoteId))  
                }
                setRouteParams({
                    pathname: data.redirect_url
                })
            } else {
                setRouteParams({
                    pathname: '/login'
                })
                showError({title: "An error has occured"});
            }
            setStatus(REDIRECT_STATES.RESOLVED)
            return;
        });
    }
}, [])

  return status === REDIRECT_STATES.PROCESSING ? (
    <div></div>
  ) : (
    <Redirect
      replace
      to={routeParams}
    />
  )
}
