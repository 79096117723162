import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import SkywatchCard from '../../Core/Cards/SkywatchCard';
import { ReactComponent as ActiveIcon } from '../../../assets/active-policy.svg';
import { POLICY_STATUS } from '../../../context/PolicyContext/types';
import { Skeleton } from '@material-ui/lab';

const PolicyCardSkeleton = ({ lineAmount, ...props}) => {
    return <SkywatchCard {...props}>
                <Grid container className='margin-top-10 margin-bottom-10'>
                    {[...Array(lineAmount)].map(() => <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                            <Skeleton />
                        </Typography>
                    </Grid>)}
                </Grid>
            </SkywatchCard>
    
}

export default PolicyCardSkeleton;
