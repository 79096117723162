import { Divider, withStyles } from '@material-ui/core';
import React from 'react';


const SkywatchDivider = withStyles(theme => ({
    root: {
        margin: '1vh 0 !important'
    },
  }))(Divider);

export default SkywatchDivider;