import React from 'react'
import { ViewEditBox } from '../ViewEditBox'
import { ReactComponent as BellSvg } from '../../../assets/bell.svg'
import FlexView from 'react-flexview/lib'
import './BellFrame.scss'

export const Frame = ({children, ...props}) => {

    return (<>
            <div className="regular-frame">
                <FlexView vAlignContent={'center'} className="space-around">
                    <div>
                        {children}
                    </div>
                </FlexView>
            </div>
        </>
    )
}