import ApiService, { HTTPMethod } from "./api.core";


export class QuoteApi {
    static async getQuote(quoteId) {
      const res = await ApiService.fetch(`profile/getUserQuoteData/${quoteId}`, HTTPMethod.GET);
      if(res.ok && res.data.start_date) {
        res.data.start_date = new Date(res.data.start_date);
      }
      return res;
    }

    static async deleteQuote(quoteId) {
      const res = await ApiService.fetch(`quote/${quoteId}`, HTTPMethod.DELETE);
      return res;
    }

    static async getUserLatestQuote() {
      return await ApiService.fetch('profile/activeQuote', HTTPMethod.GET)
    }

    static async loadQuotes() {
      return await ApiService.fetch('quote', HTTPMethod.GET);
    }

    static async updateRenewalQuote(quoteId) {
      const res = await ApiService.fetch(`quote/updateRenewal/${quoteId}`, HTTPMethod.GET);
      return res;
    }
}