import React, { useReducer, useEffect, useContext } from "react"
import { SessionService } from "../services/SessionService";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { HullApi } from "../api/hull.api";
import { Action } from "../models/Action";
import { EventEmitterService, EventKey } from "../services/EventEmitterService";
import StorageService from "../services/StorageService";
import { awaitWithLoading, isUK, removeDuplicateAdditionalInsured, fixWrongGeneratedGuid } from "../services/utils";
import { BuyingInsuranceContextStore } from "./BuyingInsuranceContext";
import { InsuranceBillingPeriod } from "../models/insurance-models/InsuranceBillingPeriodModel";
import { useHistory, useLocation } from "react-router-dom";
import { parseQuote } from "../utilities/QuoteParser";
import { UserApi } from "../api/user.api";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";
import { PaymentMethod } from "../models/user-models/CreditCardModal";


export const INSURANCE_CONTEXT_ACTIONS = {
    EDIT_DRONE: 'EDIT_DRONE',
    EDIT_EQUIPMENT: 'EDIT_EQUIPMENT',
    ADD_ADDITIONAL_INSURED: 'ADD_ADDITIONAL_INSURED',
    EDIT_ADDITIONAL_INSURED: 'EDIT_ADDITIONAL_INSURED',
    DELETE_ADDITIONAL_INSURED: 'DELETE_ADDITIONAL_INSURED',
    ACTIVE_ADDITIONAL_INSUREDS_UPDATED: 'ACTIVE_ADDITIONAL_INSUREDS_UPDATED',
    TOTAL_ADDITIONAL_INSUREDS_UPDATED: 'TOTAL_ADDITIONAL_INSUREDS_UPDATED',
    SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
    SET_EXTERNAL_USER_ID: 'SET_EXTERNAL_USER_ID' ,
}

export const ONGOING_PURCHASE = 'purchase_ongoing';

const defaultOption = {
    offerId: "",
    subscriptionPlanId: 1,
    liabilityLimit: FirebaseService.getNumber(FirebaseConfigKey.DEFAULT_LIABILITY),
    price: 1,
    commercial: false,
    taxes: 1,
    taxesMultiplier: 1,
    purchaseDiscountMultiplier: 1,
    finalPrice: 1,
    priceWithTaxes: 1,
    totalDiscount: 1,
    avgFlightScoreMultiplier: 1,
    totalCoverageHoursMultiplier: 1,
    dronePremium: 1,
    equipmentPremium: 1,
    billingPeriod: "Year",
    medicalExpenseLimitPremium: 0,
    personalInjuryLimitpremium: 0,
    territorialLimits: WorldWideOption.Default,
    territorialLimitsPremium: 0,
    creditUtilization: 1,
    finalCost: 1,
    equipment: [],
    drones: [],
    coverages: {},
    externalUserId: ''
}

const defaultState = {
    selectedOption: defaultOption,
    additionalInsuredList: [],
    autoRenew: !isUK(), 
    equipment: [], 
    equipmentTypes: [],
    drones: [],
    paymentMethod: PaymentMethod.STRIPE,
    allAdditionalInsuredList: [],
    externalUserId: '',
    setSelectedOption: (option) => { "Not Implemented"},
    setAutoRenew: (value) => {"Not Implemented"},
    addDrone: (drone) => {"Not Implemented"},
    addEquipment: (equipment) => {"Not Implemented"},
    editDrone: (drone, index) => {"Not Implemented"},
    editEquipment: (equipment, index) => {"Not Implemented"},
    dispatch: (action: Action) => {"Not Implemented"},
    deleteItem: (item => {"Not Implemented"}),
}

const reducer = (state, action: Action) => {
    console.log('InsurancePurchaseContext state', state, action)

    const newStateCalculate = () => {
        switch (action.type) {
            case 'RESET':
                return {...state, drones: [], equipment: [], autoRenew: !isUK(), additionalInsuredList: []}
            case 'OPTION_CHANGED':
                return {...state, selectedOption: action.data}
            case 'LOAD_HULL':
                return {...state, drones: action.data.drones, equipment: action.data.equipment}
            case 'LOAD_DRONES':
                return {...state, drones: action.data}
            case 'ADD_DRONE':
                    return {...state, drones: [...state.drones, action.data]}
            case 'ADD_EQUIPMENT':
                return {...state, equipment: [...state.equipment,action.data]}
            case 'EDIT_DRONE':
                return {...state, drones: state.drones.map((drone, index) => index === action.data.index ? action.data.drone : drone)}
            case 'EDIT_EQUIPMENT':
                return {...state, equipment: state.equipment.map((equip, index) => index === action.data.index ? action.data.equipment : equip)}
            case 'DELETE_DRONE':
                return {...state, drones: state.drones.filter((item, index) => index != action.data)}
            case 'DELETE_EQUIPMENT':
                return {...state, equipment: state.equipment.filter((item, index) => index != action.data)}
            case 'SET_AUTO_RENEW':
                return {...state, autoRenew: action.data}
            case 'LOAD_EQUIPMENT':
                return {...state, equipment: action.data}
            case 'EQUIPMENT_TYPES_LOADED':
                return {...state, equipmentTypes: [...action.data]}
            case 'LOAD_QUOTE':
                return {...state, ...action.data}
            case INSURANCE_CONTEXT_ACTIONS.ADD_ADDITIONAL_INSURED:
                    return {...state, additionalInsuredList: [...state.additionalInsuredList, action.data.additionalInsured]}
            case INSURANCE_CONTEXT_ACTIONS.EDIT_ADDITIONAL_INSURED:
                return {...state, additionalInsuredList: state.additionalInsuredList.map((item, index) => index === action.data.index ? action.data.additionalInsured : item)}
            case INSURANCE_CONTEXT_ACTIONS.DELETE_ADDITIONAL_INSURED: 
                return {...state, additionalInsuredList: state.additionalInsuredList.filter((item, index) => index != action.data.index)}
            case INSURANCE_CONTEXT_ACTIONS.ACTIVE_ADDITIONAL_INSUREDS_UPDATED:
                return {...state, additionalInsuredList: action.data.additional_insureds}
            case INSURANCE_CONTEXT_ACTIONS.TOTAL_ADDITIONAL_INSUREDS_UPDATED:
                return {...state, allAdditionalInsuredList: action.data.additional_insureds}
            case INSURANCE_CONTEXT_ACTIONS.SET_PAYMENT_METHOD:
                return {...state, paymentMethod: action.data }
            case INSURANCE_CONTEXT_ACTIONS.SET_EXTERNAL_USER_ID:
                return {...state, externalUserId: action.data }
          default: 
            return state;
        }
    }
     
    const newState = newStateCalculate()

    StorageService.setItem(ONGOING_PURCHASE, JSON.stringify({
         drones: newState.drones,
         equipment: newState.equipment,
         autoRenew: newState.autoRenew,
         selectedOptionLiability: newState.selectedOption.liabilityLimit,
    }))

    return newState
};


export const InsurancePurchaseContextStore = React.createContext(defaultState)

const InsurancePurchaseContext = (props) => {
    const buyingInsuranceContext = useContext(BuyingInsuranceContextStore)
    const history = useHistory()
    const location = useLocation()

    async function fetchData () {
        let types = await HullApi.getEquipmentTypes()
        dispatch(new Action('EQUIPMENT_TYPES_LOADED', types))
    }

    async function loadUserHull() {
        let resArr = await Promise.all([HullApi.getUserDrones(), HullApi.getUserEquipment()])
        if(resArr[0].ok && resArr[1].ok) {
            dispatch(new Action('LOAD_HULL', {drones: resArr[0].data.drones, equipment: resArr[1].data.equipment}))
        }
    }

    async function loadUserDrones () {
        let res = await HullApi.getUserDrones()
        if(res.ok) {
            dispatch(new Action('LOAD_DRONES', res.data.drones))
        }
    }

    async function loadUserEquipment () {
        let res = await HullApi.getUserEquipment()
        if(res.ok) {
            dispatch(new Action('LOAD_EQUIPMENT', res.data.equipment))
        }
    }

    const loadAdditionalInsureds = async () => {
        let res = await UserApi.getAdditionalInsureds();
        if (res.ok) {
            dispatch(new Action(INSURANCE_CONTEXT_ACTIONS.TOTAL_ADDITIONAL_INSUREDS_UPDATED, {additional_insureds: res.parsedData}));
        }
    }

    const [state, dispatch] = useReducer(reducer, defaultState, () => {
        let savedState = {}
        
        // Initialize from local storage
        if(StorageService.getItem(ONGOING_PURCHASE)) {
            savedState = JSON.parse(StorageService.getItem(ONGOING_PURCHASE))
            savedState.selectedOption = Object.assign({}, defaultOption, {liabilityLimit: Number.parseInt(savedState.selectedOptionLiability)})
            delete savedState.selectedOptionLiability
            // Object.assign(savedState, { startingDate: new Date() < new Date(savedState.startingDate) ? savedState.startingDate : new Date() })
            // if is logged in, remove saved drones and equipment when loading the page (will be fetched)
            if(SessionService.isLoggedIn()) {
                Object.assign(savedState, { drones: [], equipment: []})
            }
        }
        
        return Object.assign({}, defaultState, savedState)
    })


    // general initializiation 
    useEffect(() => {
        // state.equipmentTypes && state.equipmentTypes.length == 0 && fetchData()
        // SessionService.isLoggedIn() && loadUserHull()
        // SessionService.isLoggedIn() && loadAdditionalInsureds();
        

        let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, () => {
            StorageService.removeItem(ONGOING_PURCHASE)
        })

        let id2 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
            StorageService.removeItem(ONGOING_PURCHASE)
            dispatch(new Action('RESET'))
        })

        return function cleanup() {
            EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id)
            EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id2)

            //TODO: Deleting the local storage only for broker (?)
            if(SessionService.isBroker())
                StorageService.removeItem(ONGOING_PURCHASE)
        }
    }, [])

    useEffect(() => {
        // Parsing quote from query params
        var params = new URLSearchParams(location.search)

        if(!params.get('quote')) return;
        
        let quote = parseQuote(params.get('quote'))

        if(!SessionService.isBroker()) {
            if(SessionService.isLoggedIn()) {
                if (quote.drones && quote.drones.length > 0) 
                    HullApi.saveDrones(fixWrongGeneratedGuid(quote.drones)).then(() => loadUserDrones())
                if (quote.equipment && quote.equipment.length > 0) 
                    HullApi.saveEquipment(fixWrongGeneratedGuid(quote.equipment)).then(() => loadUserEquipment())
            }
            else {
                if(quote.drones && quote.drones.length > 0) {
                    dispatch(new Action('RESET'))
                    quote.drones && quote.drones.map( d => dispatch(new Action('ADD_DRONE', Object.assign({}, d, {type: 'drone'}))) )
                    quote.equipment && quote.equipment.map( e => dispatch(new Action('ADD_EQUIPMENT', e)) )
                }
            }
        }
        
        buyingInsuranceContext.loadQuote(quote)


        dispatch(new Action('LOAD_QUOTE', {
            selectedOption: Object.assign({}, defaultOption, { liabilityLimit: quote.liability || quote.liabilityLimit }),
            autoRenew: quote.auto_renew || quote.autoRenew, 
            additionalInsuredList: quote.additional_insured_list || quote.additionalInsuredList || [],
        }))

        history.replace(location.pathname, { quickFlow: SessionService.isLoggedIn() })
    }, [])

    useEffect(() => {
        let arr = []
        if(!state.selectedOption.offerId) {
            if(location.pathname.endsWith('monthly'))
                arr = buyingInsuranceContext.monthlyOffers
            else 
                arr = buyingInsuranceContext.annualOffers
        }
        else {
            if(state.selectedOption.billingPeriod == InsuranceBillingPeriod.MONTH)
                arr = buyingInsuranceContext.monthlyOffers
            else
                arr = buyingInsuranceContext.annualOffers
        }
        
        
        let option = arr.find(option => option.liabilityLimit == state.selectedOption.liabilityLimit)
        
        option && setSelectedOption(option)

    }, [buyingInsuranceContext.annualOffers, buyingInsuranceContext.monthlyOffers])


    // Incase a quote was parsed (wait for offers to load), quickFlow is True, so skip to the next step of Flow
    useEffect(() => {
        if(state.selectedOption.offerId && location.state && location.state.quickFlow) {
            history.replace()
            EventEmitterService.dispatch(EventKey.FLOW_CUSTOMIZE_FINISH)
            
        }
    }, [state.selectedOption])

    const setSelectedOption = (option) => {
        dispatch(new Action('OPTION_CHANGED', option))
    }

    const setAutoRenew = (value) => {
        dispatch(new Action('SET_AUTO_RENEW', value))
    }
    
    const addDrone = async (drone) => {
        drone.value = parseFloat(drone.value)
        if(SessionService.isLoggedIn()) {
            let res = await awaitWithLoading(HullApi.saveDrones([...state.drones, drone]))
            if(!res.ok)  {
                EventEmitterService.dispatch(EventKey.ShowError, res)
                return
            }
        }
        dispatch(new Action('ADD_DRONE', drone))
    }

    const editDrone = async (drone, index) => {
        drone.value = parseFloat(drone.value)
        if(SessionService.isLoggedIn()) {
            let res = await awaitWithLoading(HullApi.saveDrones(state.drones.map((d, dindex) => dindex === index ? drone : d)))
            if(!res.ok) {
                EventEmitterService.dispatch(EventKey.ShowError, res)
                return
            }
        }
        dispatch(new Action('EDIT_DRONE', {drone: drone, index: index}))
    }

    const addEquipment = async (equipment) => {
        equipment.quantity = parseFloat(equipment.quantity)
        equipment.value = parseFloat(equipment.value)
        if(SessionService.isLoggedIn()) {
            let res = await awaitWithLoading(HullApi.saveEquipment([...state.equipment, equipment]))
            if(!res.ok)  {
                EventEmitterService.dispatch(EventKey.ShowError, res)
                return
            }
        }
        dispatch(new Action('ADD_EQUIPMENT', equipment))
    }

    const editEquipment = async (equipment, index) => {
        equipment.quantity = parseFloat(equipment.quantity)
        equipment.value = parseFloat(equipment.value)
        if(SessionService.isLoggedIn()) {
            let res = await awaitWithLoading(HullApi.saveEquipment(state.equipment.map((equip, eindex) => eindex === index ? equipment : equip)))
            if(!res.ok) {
                EventEmitterService.dispatch(EventKey.ShowError, res)
                return
            }
        }
        dispatch(new Action('EDIT_EQUIPMENT', {equipment: equipment, index: index}))
    }

    const deleteItem = async (item, index) => {
        if(item.type == 'drone') {
            if(SessionService.isLoggedIn()) {
                let res = await awaitWithLoading(HullApi.saveDrones(state.drones.filter((item, dindex) => dindex != index)))
                if(!res.ok) {
                    EventEmitterService.dispatch(EventKey.ShowError, res)
                    return
                }
            }
            dispatch(new Action('DELETE_DRONE', index))
        }
        else {
            if(SessionService.isLoggedIn()) {
                let res = await awaitWithLoading(HullApi.saveEquipment(state.equipment.filter((item, eindex) => eindex != index)))
                if(!res.ok) {
                    EventEmitterService.dispatch(EventKey.ShowError, res)
                    return
                }
            }
            dispatch(new Action('DELETE_EQUIPMENT', index))
        }        
    }

    return (<InsurancePurchaseContextStore.Provider value={{ ...state, dispatch, setSelectedOption, setAutoRenew,
      addDrone, addEquipment,
      deleteItem, editInsuredEquipment: editEquipment, editEquipment, editDrone, editInsuredDrone: editDrone}}>
            {props.children}
        </InsurancePurchaseContextStore.Provider>)   
}

export default InsurancePurchaseContext



