import React, { useContext, useDebugValue, useEffect, useMemo, useState } from "react"
import { Divider, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { ReactComponent as ExpandMoreIcon} from '../../../assets/dropdown-icon.svg'
import { QuoteContextStore } from '../../../context/QuoteContext/QuoteContext';
import './PriceWidgets.scss'
import { prettyLimitPrice } from "../../../services/utils";
import { getInstallmentsDetails } from "../../Payment/installments";
import { useLocation } from "react-router-dom";

export const MobileWidget = () => {
    const { aircrafts , profile, price_breakdown, total_surcharge, coverages: { selected, options }, installmentsType, installmentsFee } = useContext(QuoteContextStore)
    const defaultAircraft = aircrafts[Object.keys(aircrafts)[0]];
    const location = useLocation()
    const [expanded, setExpanded] = useState(location.pathname.includes('checkout'));

    const totalSurcharge = total_surcharge ?? 0.0;
    const totalPremium = useMemo(() => {
        if(!price_breakdown) return 0;
        let total = 0;
        total += price_breakdown.physical_damage_coverage ?? 0
        total += price_breakdown.liability_coverage ?? 0
        return total
    }, [price_breakdown])

    const liabilityLabel = useMemo(() => {
        if (selected.liability_coverage === 'default' || !options || !options.liability_coverages[selected.liability_coverage]) return;
        return `${prettyLimitPrice(options.liability_coverages[selected.liability_coverage].liability_limit)}/${options.liability_coverages[selected.liability_coverage].passenger_limit ?
             prettyLimitPrice(options.liability_coverages[selected.liability_coverage].passenger_limit) : "Not Covered"}`
    }, [selected.liability_coverage, options]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(!expanded);
      };


  
    if(!!profile)
    return (
        <Grid container>
            <Accordion expanded={expanded } onChange={handleChange()} Sx={{'&:before' : {Display: 'none'}}} style={{boxShadow:"none", backgroundColor: 'transparent', width:"100%",padding:"0"}}>
                <AccordionSummary style={{padding:"0"}} expandIcon={<ExpandMoreIcon htmlColor="#164076"/>}>
                    <Grid container wrap="nowrap" justifyContent="space-between">
                        <Typography variant="h3" className="bold blue">Your Policy Details</Typography>
                        {!!installmentsType && location.pathname.includes('checkout') ? 
                            <Typography variant="h3" className="bold blue">${(totalPremium + totalSurcharge + installmentsFee).toLocaleString("en-US")}</Typography> 
                            :<Typography variant="h3" className="bold blue">${(totalPremium + totalSurcharge).toLocaleString("en-US")}</Typography>
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails style={{padding:"0",display:"block"}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item><Typography className="insured-text-big bold">Insured</Typography></Grid>
                    <Grid item><Typography className="insured-text-big">{profile.name} {profile.surname}</Typography></Grid>
                </Grid>
                <Divider />
                <Grid container justifyContent="space-between" alignItems="center">
                <Grid item><Typography className="insured-text-big bold">Aircraft type</Typography></Grid>
                    <Grid item><Typography className="insured-text-big">{defaultAircraft.make}, {defaultAircraft.model}, {defaultAircraft.year}</Typography></Grid>
                </Grid>
                <Divider />
                <Grid container>
                    <Grid item><Typography className="insured-text-big bold">Coverages</Typography></Grid>
                    <Grid item container direction="column" className="margin-top-6">
                        <Grid item container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography className="insured-text-small margin-bottom-6 margin-top-6">Liability insurance {liabilityLabel}</Typography>
                            </Grid>
                            <Grid item>
                                {price_breakdown && <Typography className="insured-text-small margin-bottom-6 margin-top-6">${!price_breakdown.liability_coverage ? "0.00" : price_breakdown.liability_coverage.toFixed(2)}</Typography>}
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography className="insured-text-small margin-bottom-6 margin-top-6">Physical Damage Coverage</Typography>
                            </Grid>
                            <Grid item>
                                {price_breakdown && <Typography className="insured-text-small margin-bottom-6 margin-top-6">${!price_breakdown.physical_damage_coverage ? "0.00" : price_breakdown.physical_damage_coverage.toFixed(2)}</Typography>}
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography className="insured-text-small margin-bottom-6 margin-top-6">Medical expense</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="insured-text-small margin-bottom-6 margin-top-6">$0.00</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                {!!installmentsType && location.pathname.includes('checkout')?
                <Grid container>
                    <Grid item><Typography className="insured-text-big bold">installments</Typography></Grid>
                {getInstallmentsDetails(totalPremium, installmentsType, installmentsFee, totalSurcharge).map((item, i) => 
                        <Grid container justifyContent="space-between" key={i}>
                            <Grid container justifyContent="space-between">
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.installment.name}</Typography></Grid>
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.installment.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography></Grid>
                            </Grid>
                            {item.fee && item.fee.amount > 0 && 
                                <Grid container justifyContent="space-between">
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.fee.name}</Typography></Grid>
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.fee.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography></Grid>
                                </Grid>}
                            {item.tax && item.tax.amount > 0 && 
                            <Grid container justifyContent="space-between">
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.tax.name}</Typography></Grid>
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.tax.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography></Grid>
                            </Grid>}
                            {item.total && 
                            <Grid container justifyContent="space-between">
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.total.name}</Typography></Grid>
                                <Grid item><Typography className="insured-text-small margin-bottom-6 margin-top-6">{item.total.amount}</Typography></Grid>
                            </Grid>}

                        </Grid>
                    )}
                    <Grid container justifyContent="space-between">
                        <Grid item><Typography className="total-price-title bold">Total price</Typography></Grid>
                        <Grid item><Typography className="total-price-title bold">${totalPremium + totalSurcharge + installmentsFee}</Typography></Grid>
                    </Grid>
                </Grid> : 
                <>
                {total_surcharge > 0 && <Grid container justifyContent="space-between">
                    <Grid item><Typography className="total-price-subtitle">Total surcharge</Typography></Grid>
                    <Grid item><Typography className="total-price-subtitle">${(totalSurcharge).toLocaleString("en-US")}</Typography></Grid>
                </Grid>}
                <Grid container justifyContent="space-between">
                    <Grid item><Typography className="total-price-title">Total price</Typography></Grid>
                    <Grid item><Typography className="total-price-title">${(totalPremium + totalSurcharge).toLocaleString("en-US")}</Typography></Grid>
                </Grid>
                </>}
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
else return <div></div>
}