import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl } from "../services/utils";

class AirportApi {
    async getCities(state, city, name) {
        let urlEncoded = buildQueryParamsUrl("airport/airportCity", {
            state,
            city,
            name
        });
        return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    async getInitCities() {
        let urlEncoded = buildQueryParamsUrl("airport/airportCity", {
            state: "",
            city: "",
            name: ""
        });
        return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    async getInitNames() {
        let urlEncoded = buildQueryParamsUrl("airport/airportName", {
            state: "",
            city: "",
            name: ""
        });
        return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    async getNames(state, city, name) {
        let urlEncoded = buildQueryParamsUrl("airport/airportName", {
            state,
            city,
            name
        });
        return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    async getAirport(state, city, name) {
        let urlEncoded = buildQueryParamsUrl("airport/airportDetails", {
            state,
            city,
            name
        });
        return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    async getAirportByCode(code) {
        let urlEncoded = buildQueryParamsUrl("airport/airportDetailsByCode", {
            code
          });
          return ApiService.fetch(urlEncoded, HTTPMethod.GET);
        }
  
    async getAirportByCodeMock(code) {
        let urlEncoded = buildQueryParamsUrl("airport/airportDetailsByCodeMock", {
            code
        });
        return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    async saveAirportPage({quote_id,name,city,state,runway_length,runway_surface,runway_elevation,code, airport_not_found}){
        return ApiService.fetch(`airport/${quote_id}`, HTTPMethod.POST, {
          name: name,
          city: city,
          code: code,
          state: state,
          runway_length: runway_length,
          runway_surface: runway_surface,
          runway_elevation: runway_elevation,
          airport_not_found: airport_not_found
        });
    }

    async goBack(quote_id){
        return ApiService.fetch(`airport/back/${quote_id}`, HTTPMethod.POST);
    }
    
      async saveAirportPageMock({name,city,code,state,runway_length,runway_surface,runway_elevation}){
        return ApiService.fetch("airport/saveAirportMock", HTTPMethod.POST, {
          name: name,
          city: city,
          code: code,
          state: state,
          runway_length: runway_length,
          runway_surface: runway_surface,
          runway_elevation: runway_elevation
        });
      }
}

export default new AirportApi();
