

import React from "react"
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { BlueButton } from './BlueButton';
import './BackButton.scss'


export const BackButton = ({children ,...props}) => 
        <BlueButton {...props} variant="contained" startIcon={<KeyboardBackspaceIcon />} disableElevation>{children}</BlueButton>
