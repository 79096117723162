import React, { useContext } from "react"
import { Grid, useMediaQuery } from "@material-ui/core"
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import 'react-credit-cards/es/styles-compiled.css';
import PaymentMethods from "./PaymentMethods";
import { QuoteContextStore } from "../../context/QuoteContext/QuoteContext";
import { MobileWidget } from "../PurchaseFlow/PriceWidgets/MobileWidget";
import './CreditCard.scss'
import { SessionContextStore } from "../../context/SessionContext/SessionContext";
import { useParams } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const CheckoutStep = ({inFlow}) => {
    const { quoteId } = useParams();
    const { finishCheckout } = useContext(QuoteContextStore);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const onPurchase = async (paymentType) => {
        if(inFlow){
            await finishCheckout(quoteId, paymentType);
        }
    }

    return (
        <React.Fragment>
            <Grid className="margin-top-medium margin-bottom-basic">
                <Grid container wrap="nowrap">
                <Elements stripe={stripePromise}>
                    <PaymentMethods onPurchase={onPurchase} inFlow={inFlow}/>
                </Elements>
                </Grid>
            </Grid>
        </React.Fragment>
        
    )
}

