import React from 'react';
import { Typography, Accordion, AccordionDetails, AccordionSummary, withStyles} from "@material-ui/core";
import { ReactComponent as ExpandMoreIcon} from '../../../assets/dropdown-icon.svg'
import './Bureaucracy.scss';
import { TRIA_LINK } from '../../../Constants';
import SkywatchLink from '../Links/SkywatchLink';


const CustomAccordionSummary = withStyles(theme => ({
    root: {
        padding: 0
    },
  }))(AccordionSummary);

  const CustomAccordionDetails = withStyles(theme => ({
    root: {
        padding: 0
    },
  }))(AccordionDetails);

const TriaDisclaimer = () => {
    return (
        <Accordion
            style={{
                boxShadow: 'none',
                backgroundColor: 'transparent',
                width: '100%',
                padding: '0'
            }}
        >
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon htmlColor="#164076"/>}>
                <Typography variant="body2">
                    COVERAGE FOR ACTS OF TERRORISM UNDER THE TERRORISM RISK
                    INSURANCE ACT OF 2002
                </Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
                <Typography variant="body2">
                    COVERAGE FOR ACTS OF TERRORISM UNDER THE TERRORISM RISK
                    INSURANCE ACT OF 2002 (AS AMENDED AND EXTENDED BY THE
                    TERRORISM RISK INSURANCE EXTENSION ACT OF 2005 AND THE
                    TERRORISM RISK INSURANCE PROGRAM REAUTHORIZATION ACTS OF
                    2007, 2015 AND 2019) <SkywatchLink className='secondary' href={TRIA_LINK} target="_blank">View Terrorism Risk Insurance Act of
                    2002 (As amended and extended by the Terrorism Risk
                    Insurance Extension Act of 2005 and The Terrorism Risk
                    Insurance Program Reauthorization Acts of 2007, 2015 and
                    2019) documentation</SkywatchLink> Coverage for Acts of Terrorism under the
                    TERRORISM RISK INSURANCE ACT OF 2002 (As amended and
                    extended by The Terrorism Risk Insurance Extension Act of
                    2005 and The Terrorism Risk Insurance Program
                    Reauthorization Acts of 2007, 2015 and 2019). Coverage
                    provided for bodily injury and property damage for which you
                    may be liable for certified acts of terrorism.
                </Typography>
            </CustomAccordionDetails>
        </Accordion>
    );
};

export default TriaDisclaimer;
