import React, { useCallback, useContext } from 'react';
import { createContext, useReducer, useEffect } from "react";
import { QuoteApi } from '../../../api/quote.api';

const defaultState = {
    quotesLoaded: false,
    quotes: []
}

const calculateState = (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case 'LOAD_QUOTES':
            return { ...state, quotes: payload, quotesLoaded: true };
        case 'DELETE_QUOTE':
            const quotes = [...state.quotes];
            const newQuotes = quotes.filter(quote => quote.quote_id !== payload.quoteId);
            return { ...state, quotes: newQuotes };
        default: 
            return state;
    }
}

const reducer = (state, action) => {
    let newState = calculateState(state, action)
    return newState
};

export const QuoteManagementContextStore = createContext(defaultState);

const QuoteManagementContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const loadQuotes = useCallback(async () => {
        const res = await QuoteApi.loadQuotes();
        dispatch({ type: 'LOAD_QUOTES', payload: res.data });
    }, []);

    const deleteQuote = useCallback(async (quoteId) => {
        const res = await QuoteApi.deleteQuote(quoteId);
        dispatch({ type: 'DELETE_QUOTE', payload: {quoteId} });
    }, []);

    return <QuoteManagementContextStore.Provider value={{ ...state, ...{dispatch}, loadQuotes, deleteQuote }}>
        {children}
    </QuoteManagementContextStore.Provider>
}

export const useQuoteManagement = () => useContext(QuoteManagementContextStore);

export default QuoteManagementContext;