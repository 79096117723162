import React, { useEffect, memo } from "react"
import { TextField , InputAdornment, Checkbox, FormControlLabel, Select, MenuItem } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab";
import { Controller, useFormContext } from "react-hook-form";
import { PatternFormat, NumericFormat } from 'react-number-format';


const ControlledTextFieldContent = ({ id, control, formState, children, name, label, IconComponent, placeholder, className, fullWidth, isIconAtStart, select, type, onChange, onBlur,...props}) => {
    const adornment = isIconAtStart ? "startAdornment" : "endAdornment";
    const inputProps = {
        [adornment]: <InputAdornment position="start">{IconComponent && <IconComponent /> }</InputAdornment>
    }

    return <Controller name={name} control={control} as={
                <TextField id={id}
                {...props}
                fullWidth={fullWidth} 
                variant="outlined" 
                select={select}
                label={label}
                onBlur={()=> console.log("ssss")}
                className={className} 
                placeholder={placeholder}
                InputProps={IconComponent ? inputProps : {}}
                error={formState.errors[name] !== undefined && formState.errors[name] !== null}
                helperText={formState.errors[name]?.message}
                onChange={onChange}
                type={type}>{children}</TextField>}
            />
}

export const ControlledTextField = (props) => {
    const { control, formState} = useFormContext();
    return <ControlledTextFieldContent { ...{ control, formState }} {...props} />
};

const BetterControlledTextFieldContent = ({ id, control, formState, children, name, label, IconComponent, placeholder, className, fullWidth, isIconAtStart, select, type, onChange, onBlur,...props}) => {
    const adornment = isIconAtStart ? "startAdornment" : "endAdornment";
    const inputProps = {
        [adornment]: <InputAdornment position="start">{IconComponent && <IconComponent /> }</InputAdornment>
    }

    return <Controller name={name} control={control} render={(params)=>
                <TextField id={id}
                {...params}
                {...props}
                fullWidth={fullWidth} 
                variant="outlined" 
                select={select}
                label={label}
                onBlur={(e) => {
                    params.onBlur(e)
                    if(!onBlur) return
                    onBlur(e)
                }}
                className={className} 
                placeholder={placeholder}
                InputProps={IconComponent ? inputProps : {}}
                error={formState.errors[name] !== undefined && formState.errors[name] !== null}
                helperText={formState.errors[name]?.message}
                onChange={(e) => {
                    params.onChange(e)
                    if(!onChange) return;
                    onChange(e)
                }}
                type={type}>{children}</TextField>}
            />
}

export const BetterControlledTextField = (props) => {
    const { control, formState} = useFormContext();
    return <BetterControlledTextFieldContent { ...{ control, formState }} {...props} />
};

const ControlledCheckBoxContent = memo(({value, setValue, register, name, label, onChange}) => {
    useEffect(()=>{
        register(name);
    },[])

    const toggle = async () => {
        setValue(name, !value);
        if(!onChange) return;
        await onChange(!value);
    }

    return <FormControlLabel
    control={<Checkbox checked={value} color="primary" onChange={toggle} name={name} />}
    label={label}/>
}, (prev, next) => prev.value ===  next.value);

export const ControlledCheckBox = (props) => {
    const form = useFormContext();
    const value = form.watch(props.name);
    return <ControlledCheckBoxContent value={value} {...props} {...form} />
}

export const ControlledAutocomplete = memo((props) => {
    const { errors, setValue, watch, register } = useFormContext();
    let value = watch(props.name);
    if(!props.multiple){
        value = (value) ? value.toString() : value;
    }

    useEffect(()=>{
        register(props.name)
    }, [])

    const handleChange = (e, val) => {
        setValue(props.name, val)
        props.onChange(e, val)
    }

    return (
        <Autocomplete
            fullwidth={true}
            getOptionLabel={props.getOptionLabel}
            loading={props.isLoading}
            multiple={props.multiple}
            options={props.options}
            onInputChange={props.onInputChange}
            onHighlightChange={props.onHighlightChange}
            onOpen={props.onOpen}
            onChange={handleChange}
            defaultValue={value}
            value={value}
            renderInput={({autoComplete, ...params}) => (
                <TextField
                    {...params}
                    {...props}
                    inputProps={{...params.inputProps, autoComplete: "none"}}
                    fullWidth={true}
                    variant="outlined"
                    onFocus={props.onFocus}
                    error={errors[props.name] !== undefined && errors[props.name] !== null}
                    helperText={errors[props.name]?.message}
                    className={props.className}
                    placeholder={props.placeholder}
                />
            )}
        />
    );
});

export const BetterControlledAutocomplete = ({name, options, isLoading, onInputChange, onHighlightChange, onOpen, onFocus, onChange, hidden, ...root}) => {
    const { control, errors, setValue } = useFormContext();
    const handleOnChange = (e, val) => {
        if(!onChange) return;
        onChange(e, val)
    }

    useEffect(() => {
        return () => {
            setValue(name, "")
        }
    },[])

    return <Controller
                shouldUnregister
                render={({...props}) => (
                    <Autocomplete
                        {...props}
                        loading={isLoading}
                        options={options}
                        onInputChange={onInputChange}
                        onHighlightChange={onHighlightChange}
                        onOpen={onOpen}
                        getOptionLabel={(option) => option.toString() || ""}
                        filterOptions={(x) => x}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...root}
                                inputProps={{...params.inputProps, hidden: hidden, autoComplete: "none"}}
                                onFocus={onFocus}
                                variant="outlined"
                                error={!!errors[name]}
                                helperText={errors[name]?.message ?? ''}
                            />
                        )}
                        onChange={(e, data) => {
                            props.onChange(data)
                            handleOnChange(e, data);
                        }}
                    />
                )}
                name={name}
                control={control}
            />
}


export const PhoneNumberTextField = ({name, className, IconComponent, isIconAtStart, fullWidth, ...props})=>{
    const { control, formState} = useFormContext();
    const adornment = isIconAtStart ? "startAdornment" : "endAdornment";
    const inputProps = {
        [adornment]: <InputAdornment position="start">{IconComponent && <IconComponent /> }</InputAdornment>
    }
    return <Controller
        as={
            <PatternFormat
                customInput={TextField}
                placeholder='Phone number'
                {...props}
                className={className}
                fullWidth={fullWidth}
                variant="outlined" 
                error={formState.errors[name] !== undefined && formState.errors[name] !== null}
                helperText={formState.errors[name]?.message}
                InputProps={IconComponent ? inputProps : {}}
                format="(###) ### ####"
            />
        }
        name={name}
        control={control}
    />
}

export const NumberedTextField = ({name, className, IconComponent, isIconAtStart, fullWidth, ...props})=>{
    const { control, formState} = useFormContext();
    const adornment = isIconAtStart ? "startAdornment" : "endAdornment";
    const inputProps = {
        [adornment]: <InputAdornment position="start">{IconComponent && <IconComponent /> }</InputAdornment>
    }
    return <Controller
        as={
            <NumericFormat
                customInput={TextField}
                {...props}
                className={className}
                fullWidth={fullWidth}
                variant="outlined" 
                error={formState.errors[name] !== undefined && formState.errors[name] !== null}
                helperText={formState.errors[name]?.message}
                InputProps={IconComponent ? inputProps : {}}
                thousandSeparator={true}
            />
        }
        name={name}
        control={control}
    />
}