import React, {useCallback, useEffect, useState} from 'react';
import { usePolicyContext } from "../context/PolicyContext/PolicyContext";
import { useSession } from "../context/SessionContext/SessionContext";
import POLICY_ACTIONS from "../context/PolicyContext/actions";
import insuranceApi from '../api/insurance.api';


const useFetchPolicies = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = usePolicyContext();
    const { isLoggedIn } = useSession();

    const fetchPolicies = useCallback(async () => {
        setIsLoading(true);
        const { data, ok } = await insuranceApi.getInsurances();
        if(!ok) return;
        dispatch({type: POLICY_ACTIONS.GET_POLICIES, payload: {
            ...data, 
            policiesExist: (data.active_policies.length + data.scheduled_policies.length + data.cancelled_policies.length + data.pending_policies.length) > 0
        }})
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if(!isLoggedIn) return;
        fetchPolicies();
    }, [isLoggedIn])

    return { fetchPolicies, isLoading };
}


export default useFetchPolicies;