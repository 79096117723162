import React, { createContext, useContext, useState } from "react";

export const LoadingContextStore = createContext({
  loading: false,
  setLoading: null,
});

const LoadingContext = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const awaitWithLoading = async (promise) => {
      setLoading(true);
      const res = await promise;
      setLoading(false);
      return res;
  }

  return (
    <LoadingContextStore.Provider value={{loading, setLoading, awaitWithLoading}}>{children}</LoadingContextStore.Provider>
  );
}

export const useLoading = () => useContext(LoadingContextStore)

export default LoadingContext;