import * as yup from 'yup';

export const paymentPlanSchema = yup
.object()
  .shape({
    paymentPlan: yup.string(),
    installments: yup.string().when(['paymentPlan'], (paymentPlan, schema) => {
        return paymentPlan === 'installments' ? schema.test("someTest", "Required", installments => {
            const res = !!installments && installments !== 'default'
            return res
        }) : schema;
    })
})
.required();