import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl } from "../services/utils";

class PilotApi {
  async savePilot({quote_id,first_name,last_name,date_of_birth,certification,pilot_rating,hours_logged,total_hours_make_and_model,
      total_hours_floats,total_hours_multi_engine,total_hours_conventional_gear,total_hours_retractable_gear, has_medical_waivers, accidents_in_last5_years, violation_or_intoxicated_or_felony_charges,
      medical_waivers_details,accidents_in_last5_years_details,violation_or_intoxicated_or_felony_charges_details}){
      return ApiService.fetch(`pilots/${quote_id}`, HTTPMethod.POST, {
        first_name,
        last_name,
        date_of_birth,
        certification,
        pilot_rating,
        hours_logged,
        total_hours_make_and_model,
        total_hours_floats,
        total_hours_multi_engine,
        total_hours_retractable_gear,
        total_hours_conventional_gear,
        has_medical_waivers,
        accidents_in_last5_years,
        violation_or_intoxicated_or_felony_charges,
        medical_waivers_details,
        accidents_in_last5_years_details,
        violation_or_intoxicated_or_felony_charges_details
      });
  }

  async goBack(quote_id){
    return ApiService.fetch(`pilots/back/${quote_id}`, HTTPMethod.POST);
  }

  async deletePilot(pilotId, quote_id){
    return ApiService.fetch(`pilots/${quote_id}`, HTTPMethod.DELETE, {
      pilot_id: pilotId
    });
  }

  async updatePilot({quote_id,first_name,last_name,date_of_birth,certification,pilot_rating,hours_logged,total_hours_make_and_model,
      total_hours_floats,total_hours_multi_engine,total_hours_conventional_gear,total_hours_retractable_gear, has_medical_waivers, accidents_in_last5_years, violation_or_intoxicated_or_felony_charges, pilot_id,
      medical_waivers_details,
      accidents_in_last5_years_details,
      violation_or_intoxicated_or_felony_charges_details}){
      return ApiService.fetch(`pilots/${quote_id}`, HTTPMethod.PUT, {
        pilot_id,
        first_name,
        last_name,
        date_of_birth,
        certification,
        pilot_rating,
        hours_logged,
        total_hours_make_and_model,
        total_hours_floats,
        total_hours_multi_engine,
        total_hours_retractable_gear,
        total_hours_conventional_gear,
        has_medical_waivers,
        accidents_in_last5_years,
        violation_or_intoxicated_or_felony_charges,
        medical_waivers_details,
        accidents_in_last5_years_details,
        violation_or_intoxicated_or_felony_charges_details
      });
  }
}

export default new PilotApi();
