import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useContext } from "react";
import { ControlledTextField } from "../../Core/Form/ControlledFields";
import * as yup from 'yup';
import '../Login.scss'
import './PasswordlessLogin.scss'
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { OrangeButton } from "../../Core/Buttons/OrangeButton";
import { useHistory } from "react-router-dom";
import { SessionContextStore } from "../../../context/SessionContext/SessionContext";
import classNames from "classnames";
import { LoadingContextStore } from "../../../context/LoadingContext/LoadingContext";
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import { ReactComponent as MailIcon} from '../../../assets/mail-closed-fill.svg';

export const userSchema = yup
.object()
  .shape({
    email: yup.string().required("Required").trim().email("Must be a valid email address"),
})
.required();

export const PasswordlessLogin = () => {
    const form = useForm({mode: 'onBlur', resolver: yupResolver(userSchema)});
    const history = useHistory()
    const {awaitWithLoading} = useContext(LoadingContextStore)
    const { getDeeplinkEmail } = useContext(SessionContextStore)
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const onSubmitLoginRequest = async (data) => {
        await awaitWithLoading(getDeeplinkEmail(data))
        window.clarityUpdateUserId(data.email)
    }

    const createNewAccount = () => {
        history.push('/insurance')
    }
    
    return (
        <React.Fragment>
            <Grid container className="page">
                <Grid item xs={12}>
                    <div className={classNames("login-info-section", {"login-airplane-image": !isMobile, "login-airplane-image_mobile": isMobile })}>
                        <Grid item xs={12} sm={6} md={5} >
                            <div className='margin-left-basic-plus margin-top-medium'>
                                <div className='text-promotion bold white title'><span className="light-blue">Welcome</span> to SkyWatch!</div>
                                <div className='text-body bold white margin-top-small'>Please login to continue</div>
                            </div>
                            <form className="margin-left-basic-plus margin-top-medium margin-right-basic-plus" autoComplete="off" onSubmit={form.handleSubmit(onSubmitLoginRequest)}>
                                <FormProvider {...form}>
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <ControlledTextField IconComponent={MailIcon} isIconAtStart fullWidth={true} name="email" className="margin-top-basic" placeholder="Email Address"/>
                                    </Grid>
                                    <Grid item xs={12} md={8} className="margin-top-basic">
                                        <OrangeButton style={{width: '100%'}} className={classNames("margin-0",{"mobile_button": isMobile})} type="submit">Login</OrangeButton>
                                    </Grid>
                                </Grid>
                                </FormProvider>
                            </form>
                        </Grid>
                        {!isMobile ?
                            <div className="text-body  black margin-top-basic bold footer">
                                Newbie? No worries
                                we got you covered  &nbsp;&nbsp; >>> &nbsp;&nbsp;
                            <span onClick={createNewAccount} className="text-basic qoute_link underline clickable bold">Start a quote</span>
                        </div>
                        :

                        <SwipeableBottomSheet topShadow={false} open={false} shadowTip={false} overflowHeight={84} style={{zIndex: 999}} swipeableViewsProps={{onTransitionEnd: true}}>
                            <div className="footer-mobile">
                                Newbie? No worries<br/>
                                we got you covered  &nbsp;&nbsp; >>> &nbsp;&nbsp;
                                <span onClick={createNewAccount} className="text-basic qoute_link underline clickable bold">Start a quote</span>
                            </div>
                        </SwipeableBottomSheet>
                        }
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}