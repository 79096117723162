import {
    makeStyles,
    Container,
    IconButton,
    Grid,
    DialogContent,
    Dialog,
    Button
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflowY: 'scroll',
        height: '100%'
    },
    container: {
        padding: '30px 0',
        // width: '40%',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    paper: {
        borderRadius: '50px',
        padding: '30px 0',
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0
        }
    }
}));

const SkywatchFormPage = ({ open, handleClose, children }) => {
    const classes = useStyles();

    return (
        <Dialog
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
        >
            <DialogContent className="bearucracy-modal-scroll-bar">
                <div className={`${classes.container} bearucracy-modal-scroll-bar`}>
                    <Grid container direction="row-reverse">
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Container>
                        {children}
                        <Grid container>
                            <Button onClick={handleClose} color="default" startIcon={<CloseIcon />}>
                                Close
                            </Button>
                        </Grid>
                    </Container>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SkywatchFormPage;
