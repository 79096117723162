import React from 'react';
import {Frame} from '../../Core/Frames/Frame'
import SkywatchWarningText from '../../Core/Text/SkywatchWarningText';
import { isInFlow } from '../../../services/utils';
import 'react-credit-cards/es/styles-compiled.css';
import FlexView from "react-flexview/lib";
import { BrowserView, isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import '../CreditCard.scss'
import { FlowBackButton } from '../../Core/Buttons/FlowBackButton';
import { MobileWidget } from '../../PurchaseFlow/PriceWidgets/MobileWidget';
var classNames = require('classnames')

const dict = {
    "applePay": "Apple pay",
    "googlePay": "Google pay"
}
const WalletPayment = ({methodOfPayment}) => {
    const location = useLocation()

    return <div>
        <Frame>When you click the contiune button above, we will redirect you to {dict[methodOfPayment]} to make today’s payment to Skywatch.</Frame>
        <SkywatchWarningText text="Please note, if you cancel your policy before the end of the policy term, you may be charged a cancellation fee."/>
        <BrowserView>
                {isInFlow(location.pathname) && <> 
                    <br/>
                    <FlexView className={classNames('space-between full-width margin-top-basic credit-card-buttons-container')}>
                    <FlowBackButton />
                </FlexView>
                </>}
        </BrowserView>
        {isMobile && <div className="margin-bottom-basic">
                    <MobileWidget />
                </div>}
    </div>
}

export default WalletPayment;