export const TABS = {
    ACTIVE_POLICIES: "activePolicies",
    SCHEDULED_POLICIES: "scheduledPolicies",
    PREVIOUS_POLICIES: "previousPolicies",
}

export const POLICY_STATUS = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    SCHEDULED: "SCHEDULED",
    CANCELED: "CANCELED"
}