import React, { useReducer, useEffect } from "react"
import InsuranceApi from "../api/insurance.api";
import { EventEmitterService, EventKey } from "../services/EventEmitterService";
import { SessionService } from "../services/SessionService";
import { addDays, awaitWithLoading, isInPurchaseFlow, isToday, isUK } from "../services/utils";
import StorageService from "../services/StorageService";
import { ONGOING_PURCHASE } from "./InsuracePurchaseContext";
import { COVERAGE_TYPES } from "../models/insurance-models/CoverageType";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";
import coveragesApi from "../api/coverages.api";
import { merge } from 'merge-anything'
import { SessionContextStore } from "./SessionContext/SessionContext";
import { ActiveInsuranceModel } from "../models/insurance-models/ActiveInsuranceModel";
import { useLocation } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import { UserApi } from '../api/user.api';
import { enhancedCoversionEvent } from "../components/Core/Utilities/GTagManger";

export const OFFERS_HISTORY_KEY = 'offers_ongoing_purchase'

export const methodsToFlags = {
    "Apple pay": "applePay",
    "Google pay": "googlePay",
    "Credit/Debit": "credit",
    "Bank": "bank"
}

export const OFFERS_ACTIOS = {
    ADD_OPERATOR: 'ADD_OPERATOR',
    DELETE_OPERATOR: 'DELETE_OPERATOR',
    EDIT_OPERATOR: 'EDIT_OPERATOR',
    SET_SPECIAL_PRICE: 'SET_SPECIAL_PRICE',
    SET_INDOOR_COVERAGE: 'SET_INDOOR_COVERAGE',
}

let liabilityCoverages = ["bodily_injury_liability", "passenger_liability", "property_damage"];

const isLiabilityBundleConfigurationAvailable = (data) => {
    return (data && liabilityCoverages.every(q => data[q] && data[q][0] && data[q][0].bundle_key && data[q][0].bundle_option_key))
}

const bundleLiabilityCoverageInfoIntoBodilyLiability = (data) => {
    console.log('xxx', isLiabilityBundleConfigurationAvailable(data));
    if (isLiabilityBundleConfigurationAvailable(data)){
        for (let bodilyInjuryLiabilityOption of data["bodily_injury_liability"]){
            let propertyDamageLiabilityOption = data["property_damage"].find(option => option.bundle_option_key == bodilyInjuryLiabilityOption.bundle_option_key);

            bodilyInjuryLiabilityOption["breakdown"] = [...bodilyInjuryLiabilityOption["breakdown"],
                { label: propertyDamageLiabilityOption["breakdown"][0].label, sublabel: propertyDamageLiabilityOption["breakdown"][0].sublabel},
            ];
        }
    }
}

const unbundleBodilyLiabilityToOtherLiabilityCoverages = (offer, options) => {
    if (liabilityCoverages.every(coverageName => coverageName in offer)){
        let bodilyInjuryLiabilityOptionGuid = offer["bodily_injury_liability"];
        let bodilyInjuryLiabilityOptionBundleId = options["bodily_injury_liability"].find(option => option.value == bodilyInjuryLiabilityOptionGuid).bundle_option_key;
        let matchingPropertyDamageOption = options["property_damage"].find(option => option.bundle_option_key == bodilyInjuryLiabilityOptionBundleId);
        let matchingPassengerLiabilityOption = options["passenger_liability"].find(option => option.bundle_option_key == bodilyInjuryLiabilityOptionBundleId);
        offer["property_damage"] = matchingPropertyDamageOption.value;
        offer["passenger_liability"] = matchingPassengerLiabilityOption.value;
    }
}

const unbundleBodilyLiabilityToOtherLiabilityCoveragesNewFlow = (offer, options) => {
    if (liabilityCoverages.every(coverageName => coverageName in offer)){
        let bodilyInjuryPropertyDamageOptionGuid = offer["bodily_injury_property_damage"];
        let bodilyInjuryPropertyDamageOptionBundleId = options["bodily_injury_property_damage"].find(option => option.value == bodilyInjuryPropertyDamageOptionGuid).bundle_option_key;
        let matchingPassengerLiabilityOption = options["passenger_liability"].find(option => option.bundle_option_key == bodilyInjuryPropertyDamageOptionBundleId);
        offer["passenger_liability"] = matchingPassengerLiabilityOption.value;
    }
}

const defaultState = {
    offerId: '',
    maximumUasInAir: 1,
    monthlyOffers: [],
    annualOffers: [],
    signature: '',
    operators: [],
    options: {
        bodily_injury_liability: [
                {labels: ['Per Person', 'Accident', 'Property'], values: [25000, 50000, 25000], value: '25/50/25'},
                {labels: ['Per Person', 'Accident', 'Property'], values: [50000, 50000, 50000], value: '50/50/50'}],
        passenger_liability: [{labels: ['Per Person', 'Accident'], values: [25000, 50000], value: '25/50'}],
        bodily_injury_underinsured: [{labels: ['Per Person', 'Accident'], values: [25000, 50000], value: '25/50'}],
        medical_payments: [{label: 'No coverage', value: 0}, {label: '$1,000', value: 1000}, {label:'$5,000', value: 5000}],
        physical_damage_comprehensive: [{label: 'No coverage', value: 0}, {label: '$1,000', value: 1000}, {label:'$5,000', value: 5000}],
    },
    optionsViolationsSignatureRequired: {
    },
    offerCart: {

    },
    offerCardErrors: {
        
    },
    offerCardWarnings: {
       // warnings: [],
        violationsId : []
    },
    plans: [{}, {}, {}],
    pricebreakdown: {},
    subscriptionStartTime:  new Date(),
    specialPrice: { enabled: "no", custom_premium: 0, custom_premium_token: '' },
    activeInsurance: new ActiveInsuranceModel(),
    changeStartingDate: (date) => {throw "Not Implemented Exception"},
    loadQuote: (quote) => {throw "Not implemented"},
    shouldPassSummary: false,
    shouldPassIssuance: false,
    shouldPassCreditCard: false,
    paymentRequest: null,
    paymentMethod: 'Credit/Debit',
    vehiclesDetailes: [],
    clientSecret: null,
    isBankLinked: false,
    shouldLoadPlans: false
}

const calculateState = (state, action) => {
    switch (action.type) {
        case 'RESET': 
            return defaultState
        case 'OFFERS_LOADED':
            return {...state, monthlyOffers: action.monthlyOffers, annualOffers: action.annualOffers}
        case 'OPTIONS_LOADED':
            return {...state, options: action.data}
        case 'OPTIONS_REQUIRE_SIGN_LOADED':
            return {...state, optionsViolationsSignatureRequired: action.data}
        case 'SAVE_SIGNATURE':
            return {...state, signature: action.data}
        case 'SET_CART_ERRORS':
            return {...state, offerCardErrors: action.data}
        case 'SET_CART_WARNINGS':
            return {...state, offerCardWarnings: action.data}
        case 'CART_CHANGED':
            let { newOffer, isNewFlow } = action.data
            if (isNewFlow) {
                unbundleBodilyLiabilityToOtherLiabilityCoveragesNewFlow(newOffer, state.options)
            } else {
                unbundleBodilyLiabilityToOtherLiabilityCoverages(newOffer, state.options);
            }
            return {...state, offerCart: newOffer}
        case 'ACTIVE_INSURANCE_CHANGED':
            return {...state, activeInsurance: action.data, offerCart: action.data.getCoverageConfiguration()}
        case 'PRICE_BREAKDOWN_CHANGED':
            return {...state, pricebreakdown: action.data, offerId: action.data.offerId, offerCardErrors: {}, vehiclesDetailes: action.data.vehiclesDetailsForUi}
        case 'CHANGE_STARTING_DATE':
            if (isToday(action.startingDate)){
                return {...state, subscriptionStartTime: new Date()}
            }
            else if(action.startingDate && action.startingDate > new Date() && !isToday(action.startingDate))
                return {...state, subscriptionStartTime: action.startingDate}
            else
                return state
        case 'LOAD_QUOTE':
            let startingDate = new Date()
            if(action.data.start_time) {
                let requestedStartingTime = new Date(action.data.start_time)
                if(requestedStartingTime > new Date() && !isToday(requestedStartingTime)) 
                    startingDate = requestedStartingTime
            } 
            return {...state, 
                        subscriptionStartTime: startingDate,
                        maximumUasInAir: action.data.maximumUasInAir || action.data.maximum_uas_in_air || 1, 
                        coverageType: action.data.coverage_type || action.data.coverageType || COVERAGE_TYPES.COMBINED,
                        operators: action.data.operators || action.data.operators || [],
                        worldWideCoverage: { territorialLimits: action.data.territorial_limits, territorialLimitsDescription: action.data.territorial_limits_description },
                        personalInjuryCoverage: Number.parseInt(action.data.personal_injury_limit),
                        medicalExpense: Number.parseInt(action.data.medical_expense_limit),
                        customPremium: Number.parseInt(action.data.custom_premium),
                        indoorCoverage: { included: action.data.indoor_coverage, description: action.data.indoor_coverage_description },
                        customPremiumToken: action.data.custom_premium_token
                    }
        case OFFERS_ACTIOS.SET_SPECIAL_PRICE: 
            return {...state, specialPrice: action.data}
        case OFFERS_ACTIOS.ADD_OPERATOR:
                return {...state, operators: [...state.operators, action.data]}
        case OFFERS_ACTIOS.EDIT_OPERATOR:
            return {...state, operators: state.operators.map((item, index) => index === action.data.index ? action.data.operator : item)}
        case OFFERS_ACTIOS.DELETE_OPERATOR: 
            return {...state, operators: state.operators.filter((item, index) => index != action.data)}
        case 'LOAD_PLANS':
            return {...state, plans: action.data}
        case 'PASS_SUMMARY':
            return {...state, shouldPassSummary: action.data}   
        case 'PASS_ISSUANCE':
            return {...state, shouldPassIssuance: action.data}   
        case 'PASS_CREDIT_CARD':
            return {...state, shouldPassCreditCard: action.data}
        case 'SET_PAYMENT_REQUEST':
            return {...state, paymentRequest: action.data}
        case 'SET_PAYMENT_METHOD':
            return {...state, paymentMethod: action.data}
        case 'SET_CLIENT_SECRET':
            return {...state, clientSecret: action.data}
        case 'LINKED_BANK_ACCOUNT': {
            return {...state, isBankLinked: action.data}
        }
        case 'LOAD_PLANS_AFTER_MVR':
            return {...state, shouldLoadPlans: action.data}
      default: 
        return state;
    }
}

const stateToLocalStorage = (state) => {
    let savedStatte = Object.assign({}, state)
    delete savedStatte.offerCardErrors
    delete savedStatte.signature
    StorageService.setItem(OFFERS_HISTORY_KEY, JSON.stringify(savedStatte))
}

const reducer = (state, action) => {
    console.log('BuyingInsuranceContext state', state, action)
    
    let newState = calculateState(state, action)
    stateToLocalStorage(newState)

    return newState
};

export const BuyingInsuranceContextStore = React.createContext(defaultState)

const BuyingInsuranceContext = (props) => {
    const sessionContext = React.useContext(SessionContextStore)

    const [state, dispatch] = useReducer(reducer, defaultState, () => {
        let savedState = {}
        // Initialize from local storage
        if(StorageService.getItem(OFFERS_HISTORY_KEY)) {
            savedState = JSON.parse(StorageService.getItem(OFFERS_HISTORY_KEY))
            savedState.subscriptionStartTime = new Date(savedState.subscriptionStartTime)
            if(savedState.subscriptionStartTime < new Date() || !savedState.subscriptionStartTime) {
                savedState.subscriptionStartTime = new Date()
            }   
        }

        return merge(defaultState, savedState)
    })

    const location = useLocation();

    liabilityCoverages = sessionContext.newPaymentFlow ? ["bodily_injury_property_damage", "passenger_liability"] : ["bodily_injury_liability", "passenger_liability", "property_damage"]

    const parseOfferViolations = (data) => {
        let err = {};
        Object.keys(data.messages).map(vehicleId => {
            err[vehicleId] = {};
            data.messages[vehicleId].map(q => JSON.parse(q)).map(q => {
                err[vehicleId][q.coverage_name] = q.error;
            });
        })

        const policyLevelCoverages = ['bodily_injury_property_damage', 'bodily_injury_liability', 'bodily_injury_underinsured', 'bodily_injury_uninsured', 'bodily_injury_uninsured_underinsured', 'medical_payments'];
        let policyLevelErrors = {};
        Object.values(data.messages).flatMap(coverageViolations => coverageViolations)
                                    .map(coverage => JSON.parse(coverage))
                                    .filter(coverage => policyLevelCoverages.includes(coverage.coverage_name))
                                    .map(coverage => {
                                        policyLevelErrors[coverage.coverage_name] = coverage.error;
                                    });
        
        err["policy"] = policyLevelErrors;

        return err;
    }

    const requestOffer = async () => {
        if(sessionContext.user.userInsuredPersons.length>0){
            if(defaultState == state) return

            let startTime = state.subscriptionStartTime
            if(startTime && isToday(startTime))
                startTime = undefined
            else {
                let dateObj = new Date(startTime);
                startTime = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 1, 0, 0));
            }
        
            let offer = Object.assign({}, state.offerCart)


            delete offer.vehicle_requests

            let res = await awaitWithLoading(coveragesApi.requestOffer({
                start_time: startTime,
                insurance_id: isInPurchaseFlow(location.pathname)? undefined: state.activeInsurance?.insuranceId,
                coverages: offer,
                vehicle_requests: state.offerCart.vehicle_requests
            }))

            if(res.ok) {
                dispatch({type: 'PRICE_BREAKDOWN_CHANGED', data: res.data})
            }
            else if(res.data && res.data.code == 400) {
                let err = parseOfferViolations(res.data);
                dispatch({type: 'SET_CART_ERRORS', data: err })
            }
            else if(res.status != 400) {

            }
        }
    }

    const loadPlans = async () => {
        let res = await coveragesApi.loadPlans()
        if(res.ok) {
            dispatch({type: 'LOAD_PLANS', data: res.parsedData.plans})
        }
        else {
            EventEmitterService.dispatch(EventKey.ShowError, res)
        }
    }

    const loadFormOptions = async () => {
        let res = await coveragesApi.loadOptions()
        if(res.ok) {
            let parsedOptions = {}
            
            bundleLiabilityCoverageInfoIntoBodilyLiability(res.data.options);
            Object.keys(res.data.options).map(name => {
                parsedOptions[name] = res.data.options[name].map(item => {
                    if(item.breakdown && item.breakdown.length > 1)
                        return {
                            labels: item.breakdown.map(k => k.label),
                            values: item.breakdown.map(k => k.sublabel),
                            value: item.value,
                            tooltip: item.tooltip,
                            bundle_option_key: item.bundle_option_key,
                            bundle_key: item.bundle_key,
                            name: item.name
                        }
                    else if(item.breakdown)
                        return {
                            label: item.breakdown[0].sublabel,
                            value: item.value,
                            tooltip: item.tooltip,
                            bundle_option_key: item.bundle_option_key,
                            bundle_key: item.bundle_key,
                            name: item.name
                        }
                    else {
                        return {
                            label: item.subtitle,
                            value: item.value,
                            tooltip: item.tooltip,
                            bundle_option_key: item.bundle_option_key,
                            bundle_key: item.bundle_key,
                            name: item.name
                        }
                    }
                })
            })
            let parsedOptionsViolationsSignatureRequired = res.data.violations_requiring_signing 

            dispatch({type: 'OPTIONS_LOADED', data: parsedOptions})
            dispatch({type: 'OPTIONS_REQUIRE_SIGN_LOADED', data: parsedOptionsViolationsSignatureRequired})
        }
        else {
            EventEmitterService.dispatch(EventKey.ShowError, res)
        }
    }

    const checkCoveragesViolations = () => {
        let optionsViolationsSignatureRequired = state.optionsViolationsSignatureRequired
        if (Object.keys(optionsViolationsSignatureRequired).length === 0){
            return
        }
        
        let userVehiclesReq = state.offerCart.vehicle_requests
        if (userVehiclesReq == null){
            return 
        }
        let userVehicles = Object.keys(userVehiclesReq).map(key => {return userVehiclesReq[key]})
        let coveragesSelectionsToWarning = {
            violationsId: []
        }
        optionsViolationsSignatureRequired.forEach(element => {
             let firstCoverageName = element.coverage_name1
             let secondCoverageName = element.coverage_name2

            if (state.offerCart[firstCoverageName] === element['coverage_option_id1'] && state.offerCart[secondCoverageName] ===  element['coverage_option_id2']){
                coveragesSelectionsToWarning.violationsId.push(element.coverage_option_violation_with_signing_id)
                coveragesSelectionsToWarning[firstCoverageName] = element['signing_text']
            }

            if (sessionContext.user.state == 'IN'){
             userVehicles.forEach(x => {
                 if (state.offerCart[firstCoverageName] === element['coverage_option_id1'] && x.coverages[secondCoverageName] ===  element['coverage_option_id2']){   
                    coveragesSelectionsToWarning.violationsId.push(element.coverage_option_violation_with_signing_id)
                     coveragesSelectionsToWarning[firstCoverageName] = element['signing_text']
                 } 
             })
         }
        })

     dispatch({type: 'SET_CART_WARNINGS', data: coveragesSelectionsToWarning})
    }

    // calculating coverages violations
    useEffect(() => {
        checkCoveragesViolations()
    }, [state.offerCart, state.optionsViolationsSignatureRequired, sessionContext.user])

    useEffect(() => {
        // if (SessionService.isAdmin()){
        //     loadFormOptions();           
        // }
        // else{
            sessionContext.user.userInsuredPersons.length > 0 && sessionContext.user.userVehicles.every(x => x.new_vehicle_req_details == "") && 
                SessionService.isLoggedIn() && 
                !sessionContext.doOrphanVehiclesExist() && Promise.all([loadPlans(), loadFormOptions()])

        //}

        return function cleanup() {
            //TODO: Deleting the local storage only for broker (?)
            if(SessionService.isBroker())
                StorageService.removeItem(OFFERS_HISTORY_KEY)
        }

    }, [sessionContext.user])

    useEffect(() => {
        let id2 = EventEmitterService.subscribe(EventKey.USER_LOGGED_IN, () => {
            if (SessionService.isAdmin()){
                loadFormOptions();
            } else 
             if (sessionContext.user.userInsuredPersons.length > 0 && !sessionContext.doOrphanVehiclesExist() && sessionContext.user.userVehicles.every(x => x.new_vehicle_req_details == "")){
                Promise.all([loadPlans(), loadFormOptions()])
            }
        })

        let id3 = EventEmitterService.subscribe(EventKey.FLOW_PROFILE_FINISH, () => {
            // In case State has changed so offers will reflect taxes
            // loadOffers()
        })

        let id4 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
            dispatch({type: 'RESET'})
        })

        let id5 = EventEmitterService.subscribe(EventKey.RECEIVED_ACTIVE_INSURANCE, (activeInsurance) => {
            dispatch({type: 'ACTIVE_INSURANCE_CHANGED', data: activeInsurance});
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.USER_LOGGED_IN, id2)
            EventEmitterService.unsubscribe(EventKey.FLOW_PROFILE_FINISH, id3)
            EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id4)
            EventEmitterService.unsubscribe(EventKey.RECEIVED_ACTIVE_INSURANCE, id5)
        }
    }, [state])

    useEffect(() => {
        if(state != defaultState)
            requestOffer()
    }, [state.subscriptionStartTime, state.specialPrice, state.offerCart])

    useEffect(() => {
        //generatePlaidToken();

        // Reset Starting date after successfull purchase        
        let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, () => {
            //O.B - commented out, not sure if needed
            // changeStartingDate(new Date())
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id)
        }
    }, [])

    const changeStartingDate = (startingDate: Date) => {
        dispatch({type: 'CHANGE_STARTING_DATE', startingDate: startingDate})
    }


    const loadQuote = (quote) => {
        dispatch({type: 'LOAD_QUOTE', data: quote})
    }

    const passSummary = (shouldPass) => {
        dispatch({type: 'PASS_SUMMARY', data: shouldPass})
    }

    const passIssuance = (shouldPass) => {
        dispatch({type: 'PASS_ISSUANCE', data: shouldPass})
    }

    const loadPlansAfterMvrChange = (shouldLoadPlans) => {
       const result = state.shouldLoadPlans || shouldLoadPlans;
        dispatch({type: 'LOAD_PLANS_AFTER_MVR', data: result})
    }
    const passCreditCard = (shouldPass) => {
        dispatch({type: 'PASS_CREDIT_CARD', data: shouldPass})
    }

    const setPaymentRequest = (paymentRequest) => {
        dispatch({type: 'SET_PAYMENT_REQUEST', data: paymentRequest})
    }

    const setPaymentMethod = (paymentMethod) => {
        dispatch({type: 'SET_PAYMENT_METHOD', data: paymentMethod})
    }

    const setClientSecret = (token) => {
        dispatch({type: 'SET_CLIENT_SECRET', data: token})
    }
    
    const setIsBankLinked = (isLinked) => {
        dispatch({type: 'LINKED_BANK_ACCOUNT', data: isLinked});
    }

    const flipSpecialPrice = () => {
        if(state.specialPrice.enabled == "yes")
            dispatch({type: OFFERS_ACTIOS.SET_SPECIAL_PRICE, data: {enabled: 'no',
             custom_premium: state.specialPrice.custom_premium,
             custom_premium_token: state.specialPrice.custom_premium_token,
            }})
        else
            dispatch({type: OFFERS_ACTIOS.SET_SPECIAL_PRICE, data: {enabled: 'yes',
             custom_premium: state.specialPrice.custom_premium,
             custom_premium_token: state.specialPrice.custom_premium_token,
            }})
    }

    const setSpecialPrice = (premium, premiumToken) => {
        dispatch({type: OFFERS_ACTIOS.SET_SPECIAL_PRICE, data: {enabled: 'yes',
         custom_premium: premium,
         custom_premium_token: premiumToken}})
    }

    const getClientSecret = async () => {
    }

    const choosePlan = (plan) => {

        let configuration = {
            vehicle_requests: {},
        }

        plan.coverages.filter(coverage => coverage.type == 'policy').forEach(coverage => {
            configuration[coverage.name] = coverage.value
        })

        let vehicleValues = {}
        plan.coverages.filter(coverage => coverage.type == 'vehicle').forEach(coverage => {
            vehicleValues[coverage.name] = coverage.value
        })

        sessionContext.user.userVehicles.forEach(vehicle => {
            configuration.vehicle_requests[vehicle.id] = {
                coverages: {...vehicleValues},
                is_diminishing_deductible: false
            }
        })

        //TODO: remove this line
        coveragesApi.hackSavePlan(plan.id)

        enhancedCoversionEvent(sessionContext.user.email, sessionContext.user.phoneNumber, "Continue-button-at-operators");
        
        dispatch({type: 'CART_CHANGED', data: { newOffer: configuration, isNewFlow: sessionContext.newPaymentFlow }})
    }

    const purchaseOffer = async () => {
        return await coveragesApi.purchaseOffer(state.offerId)
    }


    return (<BuyingInsuranceContextStore.Provider value={{ ...state, dispatch, choosePlan, setSpecialPrice, flipSpecialPrice, changeStartingDate, loadQuote, passSummary, passIssuance, passCreditCard, setPaymentRequest, setPaymentMethod, setClientSecret, getClientSecret, setIsBankLinked, loadPlans, loadPlansAfterMvrChange }}>
            {props.children}
        </BuyingInsuranceContextStore.Provider>)   
}

export default BuyingInsuranceContext



