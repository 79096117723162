import { CircularProgress, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { LayoutMain } from '../Layout/LayoutMain';
import { useQuoteManagement } from './context/QuoteManagementContext';
import QuoteCard from './QuoteCard';
import './QuoteManagement.scss'

const QuoteManagement = () => {
    const { quotes, loadQuotes, deleteQuote, quotesLoaded } = useQuoteManagement();
    const isMobileQuery = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );

    useEffect(() => {
        loadQuotes();
    }, []);

    return (
        <LayoutMain>
            {!isMobileQuery ? (
                <div className="policy-management-title">
                    <Grid container justifyContent="left">
                        <Typography variant="h1" className="blue">
                            Manage quotes
                        </Typography>
                    </Grid>
                </div>
            ) : (
                <Grid
                    container
                    className="margin-top-medium"
                    justifyContent="center"
                >
                    <Typography variant="h1" className="blue">
                    Manage quotes
                    </Typography>
                </Grid>
            )}
            <div className="margin-top-medium margin-bottom-basic">
                {!quotesLoaded ? <Grid container><CircularProgress/> </Grid>: quotes.length > 0 ? quotes.map((quote) => (
                    <QuoteCard quote={quote} deleteQuote={deleteQuote} />
                )) : <Typography variant="h5" className='no-quotes-text'>No quotes found</Typography>}
            </div>
        </LayoutMain>
    );
};

export default QuoteManagement;
