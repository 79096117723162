import React, { Suspense, lazy } from 'react';
import './styles/styles.scss'
import './styles/margins.scss'
import './styles/colorstyles.scss'
import './styles/safarioverrides.scss'
import { FirebaseService, FirebaseConfigKey } from './services/FirebaseService';
import SessionContext from './context/SessionContext/SessionContext';
import InsurancePurchaseContext from './context/InsuracePurchaseContext';
import QuoteContext from './context/QuoteContext/QuoteContext';
import { lazyWithPreload } from './utilities/RLazyLoading';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs'
import { FlowBrokerProtectedRoute } from './components/Core/Routes/FlowBrokerProtectedRoute';
import { SkywatchThemeSwitcher } from './theme/SkywatchThemeSwitcher';
import { changeFavicon, isUK } from './services/utils';
import { SessionService } from './services/SessionService';
import BrokerConfigService from './services/BrokerConfigService';
import { ServiceWorkerWrapper } from './serviceworker/ServiceWorkerWrapper';
import LoadingContext from './context/LoadingContext/LoadingContext';
import AlertContext from './context/AlertContext/AlertContext';
import {OnlyGuestsRoute} from './components/Core/Routes/OnlyGuestsRoute'
import { PasswordlessLogin } from './components/Login/PasswordlessLogin/PasswordlessLogin';
import PolicyContext from './context/PolicyContext/PolicyContext';
import PolicyManagement from './components/PolicyManagement/PolicyManagement';
import Account from './components/Account/Account';
import EditPilotRenewal from './components/PolicyManagement/EditRenewal/EditPilotsRenewal/EditPilotRenewal';
import Admin from './components/Admin/Admin';
import BrokerLogin from './components/Login/BrokerLogin/BrokerLogin';
import ForgotPassword from './components/Login/ForgotPassword/ForgotPassword';
import { EntranceProtectedRoute } from './components/Core/Routes/EntranceProtectedRoute';
import QuoteManagement from './components/QuoteManagement/QuoteManagement';
import QuoteManagementContext from './components/QuoteManagement/context/QuoteManagementContext';
import PostLoginProtectedRoute, { PolicyProtectedRoute } from './components/Core/Routes/PostLoginProtectedRoute';

const PurchaseFlow = lazyWithPreload(() => import( /* webpackChunkName: "MonthlyInsurance" */ './components/PurchaseFlow/PurchaseFlow').then(module => ({ default: module.PurchaseFlow })));
// const PolicyManagementLayout = lazyWithPreload(() => import( /* webpackChunkName: "PolicyManagementLayout" */'./components/PolicyManagement/PolicyManagementLayout').then(module => ({ default: module.PolicyManagementLayout })))
const SideMenu = lazyWithPreload(() => import(/* webpackChunkName: "SideMenu" */ './components/SideMenu/SideMenu'))
PurchaseFlow.preload()
// PolicyManagementLayout.preload()
SideMenu.preload()
const ServerResponsePopup = lazy(() => import(/* webpackChunkName: "ServerResponsePopup" */'./components/PopUps/ServerResponsePopup').then(module => ({default: module.ServerResponsePopup})));
const LoadingAnimation = lazy(() => import(/* webpackChunkName: "LoadingAnimation" */'./components/PopUps/LoadingAnimation'));
const PaymentLoadingAnimation = lazy(() => import(/* webpackChunkName: "LoadingAnimation" */'./components/PopUps/PaymentLoadingAnimation'));

Number.prototype.toLocaleStringToFixed = function() {
  return this.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
};

class App extends React.Component {

  async componentDidMount() {
    await FirebaseService.init()
    changeFavicon()
  }

  render () {
    return (
        <MuiPickersUtilsProvider utils={DayJSUtils}>
            <>
                <ServiceWorkerWrapper />
                {/* <SkyCookiesConsent /> */}
                <SkywatchThemeSwitcher>
                    <LoadingContext>
                      <AlertContext>
                        <Suspense fallback={<div />}>
                            <Router>
                                <SessionContext>
                                  <PolicyContext>
                                  <QuoteContext>
                                    <SideMenu />
                                    <Switch>
                                        <Route path="/insurance">
                                            <QuoteContext>
                                                <InsurancePurchaseContext>
                                                    <PurchaseFlow />
                                                </InsurancePurchaseContext>
                                            </QuoteContext>
                                        </Route>

                                        <PostLoginProtectedRoute path="/post-login" />

                                        <Route path="/policy-management">
                                          <PolicyManagement />
                                        </Route>

                                        <Route path="/account">
                                          <Account />
                                        </Route>

                                        <Route path="/broker/accounts/active">
                                          <Account />
                                        </Route>

                                        <Route path="/quotes">
                                          <QuoteManagementContext>
                                            <QuoteManagement />
                                          </QuoteManagementContext>
                                        </Route>

                                        <Route path="/admin">
                                          <Admin />
                                        </Route>

                                          <EntranceProtectedRoute path="/entrance">
                                          </EntranceProtectedRoute>

                                        <Route path="/edit-renewal">
                                            <EditPilotRenewal />
                                        </Route>

                                        <OnlyGuestsRoute path="/login">
                                          <PasswordlessLogin />
                                        </OnlyGuestsRoute>

                                        <OnlyGuestsRoute path="/forgot-password">
                                          <ForgotPassword />
                                        </OnlyGuestsRoute>

                                        <OnlyGuestsRoute path="/broker-login">
                                              <BrokerLogin />
                                        </OnlyGuestsRoute>

                                        <Route>
                                        <Redirect to={{
                                          pathname: '/insurance/profile',
                                          search: window.location.search,
                                        }} />
                                        </Route>
                                    </Switch>
                                    </QuoteContext>
                                  </PolicyContext>
                                </SessionContext>
                            </Router>
                        </Suspense>

                        <Suspense fallback={<div />}>
                            <LoadingAnimation />
                            <PaymentLoadingAnimation />
                            <ServerResponsePopup />
                        </Suspense>
                        </AlertContext>
                    </LoadingContext>
                </SkywatchThemeSwitcher>
            </>
        </MuiPickersUtilsProvider>
    );
  }
}

export default App;
