
import React from "react"
import { ReactComponent as Visa} from '../../assets/cards/visa.svg'
import { ReactComponent as Discover} from '../../assets/cards/discover.svg'
import { ReactComponent as Amex} from '../../assets/cards/amex.svg'
import { ReactComponent as Diners} from '../../assets/cards/diners.svg'
import { ReactComponent as MasterCard} from '../../assets/cards/mastercard.svg'
import { ReactComponent as UnionPay} from '../../assets/cards/unionpay.svg'
import { ReactComponent as Unknown} from '../../assets/cards/unknown.svg'

export const CARDS = {
    JCB: 'jcb', 
    Visa: 'visa',
    MasterCard: 'mastercard',
    Amex: 'amex',
    Discover: 'discover',
    Diners: 'diners',
    UnionPay: 'unionpay',
    Unknown: 'unknown',
}

export const CreditCardLogo = (props) => {

    const getLogo = (brand) => {
        switch(brand.toLowerCase()) {
            case CARDS.Visa:
                return <Visa className="large-icon" />
            case CARDS.MasterCard:
                return <MasterCard className="large-icon" />
            case CARDS.Amex:
                return <Amex className="large-icon" />
            case CARDS.Discover:
                return <Discover className="large-icon" />
            case CARDS.Diners:
                return <Diners className="large-icon" />
            case CARDS.UnionPay:
                return <UnionPay className="large-icon" />
            case CARDS.JCB:
                return <img src={process.env.PUBLIC_URL + '/assets/cards/jcb.jpg'} className="large-icon" />
            default:
                return <Unknown className="large-icon" />
        }
    }

    return (<div style={{paddingTop: '5px'}}>{getLogo(props.brand)} </div>)
}