import React from 'react';

export const BASE_THEME = {
    blue: '#164076',
    superLightBlue: '',
    lightteal: '#55ccde',
    light: '#55ccde',
    darkblue: '#003777',
    darkskyblue: '#152442',
    primary: '#164076',
    secondary: '#f67347',
    grey: '#6e707a',
    lightGrey: '#b0bed1',
    superLightGrey: '#dbe2ea',
    buttonactive: '#0058da',
    powderblue: '#c2d1ea',
    verylightteal: '#e8f5ff',
    buttonhover: '#4199ff',
    buttonshadow: '#1968fc99',
    bluegrey: '#dbe5f5',
    lightgraybox: '#f5f9fd',
    logoHeight: '40px',
    logoHeightMobile: '20px',
    useColoredBackground: false,
    hamburger: '#ffffff',
}

export const THEMES = {
    skywatch: {
        theme: Object.assign({}, BASE_THEME, {
                logoHeight: '20px',
                useColoredBackground: true}),
    },
    voom: {
        theme: Object.assign({}, BASE_THEME, {
            blue: "#164076",
            bluegrey: "#eeecf7",
            lightgraybox: "#eeecf7",
            logoHeight: "50px",
            logoHeightMobile: "40px",
        }),
        redeemCode: "voominsurance",
    },
    ajg: {
        redeemCode: 'galla2019',
        theme: Object.assign({}, BASE_THEME, {
            blue: '#002a44',        
            primary: '#002a44',
            logoHeight: '35px',
            logoHeightMobile: '30px',
            logo: 'ajg_logo.svg',
        })
    }
}

export const SkywatchThemeContext = React.createContext(THEMES.BASE_THEME);