import { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {LoadingContextStore} from "../LoadingContext/LoadingContext";
import { AuthApi } from '../../api/auth.api';
import { SessionService } from "../../services/SessionService";
import { AlertContextStore } from "../AlertContext/AlertContext";
import brokerApi from "../../api/broker.api";
import StorageService from "../../services/StorageService";
import { UserApi } from '../../api/user.api'

const SESSION_ACTIONS = {
    USER_LOGGED_IN: "USER_LOGGED_IN",
    USER_LOGGED_OUT: "USER_LOGGED_OUT",
    PASSWORD_LOGIN: "PASSWORD_LOGIN",
    LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
    SET_QUOTE: "SET_QUOTE",
    IMPERSONATION: "IMPERSONATION",
    LOAD_USER: "LOAD_USER",
    EXIT_IMPERSONATION: "EXIT_IMPERSONATION",
    DEEPLINK_LOGIN: "DEEPLINK_LOGIN"
}

const afterLoginRedirect = ({has_policies, has_multiple_quotes, active_quote_id}) => {
    if(has_policies) {
        return '/policy-management';
    } else if(has_multiple_quotes) {
        return '/quotes';
    } else {
        return `/insurance/profile${active_quote_id ? `/${active_quote_id}` :''}`;
    }
}

export const useActions = (dispatch) => {
    const history = useHistory();
    const { awaitWithLoading } = useContext(LoadingContextStore);
    const { showServerError, showError, showInfo } = useContext(AlertContextStore);



    return {
        register: async function({email, zipcode, state}) {
            const { data, ok } = await AuthApi.registerPasswordless(email, zipcode, state)
            if(!ok) {
                const { code } = data;
                if(code === 102) {
                    showInfo({
                        title: "Check your email",
                        message: `We've sent you an email with a verification link. Please check your inbox\n`,
                        confirmButtonText: "Ok",
                        showCancel: false,
                    });
                } else {
                    showServerError({title: "An Error has occurred", message: "Please try again later."});
                }
                return;
            };
            SessionService.setUserToken(email, data.token)
            dispatch({
                type: SESSION_ACTIONS.USER_LOGGED_IN,
                payload: { ...data, email}
            })
            return ok;
        },
        impersonateUser: async function({email}) {
            dispatch({
                type: SESSION_ACTIONS.SET_QUOTE,
                payload: null
            });
            const {data, ok} = await awaitWithLoading(brokerApi.findUserOnBehalfBroker(email));
            if(!ok) {
                const { title } = data;
                showServerError({title});
                return;
            }
            const { token } = data;
            SessionService.setBrokerOnBehalfToken(token)
            const { data: {active_quote_id, has_policies, has_multiple_quotes}, ok: getQuoteStatus } = await awaitWithLoading(UserApi.getUserStatus());
            if(!getQuoteStatus) {
                const { title } = data;
                showServerError({title});
                return;
            }

            dispatch({
                type: SESSION_ACTIONS.IMPERSONATION,
                payload: { email, token, active_quote_id, has_policies, has_multiple_quotes }
            })
            history.push('/post-login');
        },
        exitImpersonation: async function() {
            SessionService.resetBrokerOnBehalfToken();
            dispatch({
                type: SESSION_ACTIONS.EXIT_IMPERSONATION,
            })
        },
        logout: async function() {
            SessionService.deleteSession();
            dispatch({
                type: SESSION_ACTIONS.USER_LOGGED_OUT
            });
            history.push('/login');
        },
        passwordlessLogin: async function(data) {
            const res = await awaitWithLoading(AuthApi.passwordlessLogin(data.email, data.last_name, data.zip_code));
            if(res.ok){
                SessionService.setUserToken(data.email, res.parsedData.token);
                const { data: {has_policies, has_multiple_quotes, active_quote_id}, ok: getQuoteStatus } = await awaitWithLoading(UserApi.getUserStatus());
                if(!getQuoteStatus) {
                    showServerError({title: "failed getting active quote"});
                    return;
                }
                dispatch({
                    type: SESSION_ACTIONS.USER_LOGGED_IN,
                    payload: { active_quote_id, email: data.email, has_policies, has_multiple_quotes }
                })

                history.push('/post-login');
            } else {
                const errorTitle = res.data.title;
                const errorMessage = Object.keys(res.data.messages).length > 0 ? res.data.messages["message"] : "";
                showServerError({title: errorTitle, message: errorMessage});
            }

        },
        getDeeplinkEmail: async function({email}) {
            const { data, ok, status } = await awaitWithLoading(AuthApi.getDeeplink({email}));
            if(!ok) {
                if(status === 404) {
                    showInfo({title: data.title})
                } else {
                    showInfo({title: "Login failed due to an error"})
                }
            } else {
                showInfo({
                    title: "Check your email",
                    message: `We've sent you an email with a verification link. Please check your inbox\n`,
                    confirmButtonText: "Ok",
                    showCancel: false,
                });
            }
        },
        setQuote: async function(quoteId) {
            dispatch({
                type: SESSION_ACTIONS.SET_QUOTE,
                payload: quoteId ? quoteId : null
            });
        },
        forgotPassword: async function({email}) {
            const {data, ok} = await awaitWithLoading(AuthApi.sendForgotPasswordEmail(email));
            if(!ok) {
                const { title } = data;
                showServerError({title});
                return;
            }
            showInfo({
                title: "Check your email",
                message: `We've sent a reset password email to ${email}.\n`,
                confirmButtonText: "Ok",
                onConfirm: async () => {
                    history.push('/login')
                }
            });
        },
        login: async function({email, password}) {
            const { data, ok } = await awaitWithLoading(AuthApi.login(email, password));
            if(!ok) {
                const { title } = data;
                showServerError({title});
                return;
            }
            const { token } = data;
            SessionService.setUserToken(email, token)
            const isAdmin = SessionService.isAdmin();
            const { data: {has_policies, has_multiple_quotes, active_quote_id}, ok: getQuoteStatus } = await awaitWithLoading(UserApi.getUserStatus());
            if(!getQuoteStatus) {
                showServerError({title: "failed getting active quote"});
                return;
            }

            dispatch({
                type: SESSION_ACTIONS.PASSWORD_LOGIN,
                payload: {
                    isAdmin,
                    has_policies, has_multiple_quotes,
                    active_quote_id,
                    email
                }
            });
            if(isAdmin) {
                history.push('/admin');
            } else {
                history.push('/post-login');
            }
        },
        setImpersonation: async function() {
            const token = SessionService.getStoredBrokerOnBehalfToken();
            if(!token) return;
            const {sub: email} = SessionService.getStoredBrokerOnBehalfEmail();
            dispatch({
                type: SESSION_ACTIONS.IMPERSONATION,
                payload: { email, token }
            })
        },
        loadUser: async function() {
            const token = SessionService.getStoredToken();
            if(!token) return;
            const {sub: email} = SessionService.getStoredSessionObject();
            const isAdmin = SessionService.isAdmin();
            const { data: {has_policies, has_multiple_quotes, active_quote_id}, ok: getQuoteStatus } = await awaitWithLoading(UserApi.getUserStatus());
            dispatch({
                type: SESSION_ACTIONS.LOAD_USER,
                payload: { email, token, isAdmin, has_policies, has_multiple_quotes, active_quote_id }
            })
        },
        validateDeeplink: async function({deeplinkToken, profile}) {
            const res = await AuthApi.validateDeeplink({token: deeplinkToken, profile});
            const { data, ok } = res;
            if(!ok || !data.token) {
                return res;
            }
            SessionService.setUserToken(data.user_name, data.token);
            return res;
        }
    }
}

export default SESSION_ACTIONS;