import React from "react";
import { ErrorOutline } from '@material-ui/icons';
import { Grid } from "@material-ui/core";

const SkywatchWarningText = ({text}) => {
    return (            
    <Grid className="margin-top-basic" container wrap="nowrap" alignItems="center" spacing={1}>
        <Grid item>
            <ErrorOutline/>
        </Grid>
        <Grid item>
            {text}
        </Grid>
    </Grid>)
}

export default SkywatchWarningText;