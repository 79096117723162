import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom";
import { SessionContextStore } from "../../../context/SessionContext/SessionContext";


export const PostLoginProtectedRoute = ({children, quoteId, ...rest}) => {
    const { user, impersonationDetails, hasMultipleQuotes, hasPolicies, activeQuoteId } = useContext(SessionContextStore);

    return (
        <Route
          {...rest}
          render={(props) => {
            if (!user && !impersonationDetails) {
              return <div />
            } else {
                if(hasPolicies){
                    return <Redirect to={{
                      pathname: "/policy-management",
                      state: { from: props.location}
                    }} />
                } else if(hasMultipleQuotes) {
                    return <Redirect to={{
                        pathname: "/quotes",
                        state: { from: props.location}
                      }} />
                } else {
                    return <Redirect to={{
                        pathname: `/insurance/profile${activeQuoteId ? `/${activeQuoteId}` : ""}`,
                        state: { from: props.location}
                      }} />
                }
            }
          }}
        />
      );
}

export default PostLoginProtectedRoute;