import { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import insuranceApi from "../../api/insurance.api";
import { AlertContextStore } from "../AlertContext/AlertContext";
import {LoadingContextStore} from "../LoadingContext/LoadingContext"
import { SessionContextStore } from "../SessionContext/SessionContext";

const POLICY_ACTIONS = {
    GET_POLICIES: "GET_POLICIES",
    CHANGE_TAB: "CHANGE_TAB",
    TOGGLE_AUTO_RENEW:"TOGGLE_AUTO_RENEW"
}

export const useActions = (dispatch, state) => {
    const {awaitWithLoading} = useContext(LoadingContextStore)
    return {
        getPolicies: async function() {
            const {data, ok} = insuranceApi.getInsurances();
            dispatch({
                type: POLICY_ACTIONS.GET_POLICIES,
                payload: data
            })
        },
        toggleAutoRenew: async function(policyId) {
            const {data, ok} = await awaitWithLoading(insuranceApi.toggleAutoRenew(policyId));
            dispatch({
                type: POLICY_ACTIONS.TOGGLE_AUTO_RENEW
            })
        },
        changeTab: async function(tab) {
            dispatch({
                type: POLICY_ACTIONS.CHANGE_TAB,
                payload: tab
            })
        }
    }
}

export default POLICY_ACTIONS;
