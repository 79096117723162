import React, { useCallback, useContext } from 'react';
import { createContext, useReducer, useEffect } from "react";
import POLICY_ACTIONS, { useActions } from "./actions";
import { useLocation } from 'react-router-dom';
import { NightsStay } from '@material-ui/icons';
import insuranceApi from '../../api/insurance.api';
import { useSession } from '../SessionContext/SessionContext';
import useFetchPolicies from '../../hooks/useFetchPolicies';
import { awaitWithLoading } from '../../services/utils';

export const TABS = {
    ACTIVE_POLICIES: "activePolicies",
    SCHEDULED_POLICIES: "scheduledPolicies",
    PREVIOUS_POLICIES: "previousPolicies",
}

const defaultState = {
    managementTab: TABS.ACTIVE_POLICIES,
    active_policies: [],
    scheduled_policies: [],
    cancelled_policies: [],
    pending_policies: [],
    policiesExist: false
}

const calculateState = (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case POLICY_ACTIONS.GET_POLICIES:
            return { ...state, ...payload };
        case POLICY_ACTIONS.TOGGLE_AUTO_RENEW:
            return { ...state};
        case POLICY_ACTIONS.CHANGE_TAB:
            return { ...state, managementTab: payload };
        default: 
            return state;
    }
}

const reducer = (state, action) => {
    let newState = calculateState(state, action)
    return newState
};

export const PolicyContextStore = createContext(defaultState);

const PolicyContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const actions = useActions(dispatch, state);


    return <PolicyContextStore.Provider value={{ ...state, ...actions, ...{dispatch} }}>
        {children}
    </PolicyContextStore.Provider>
}

export const usePolicyContext = () => useContext(PolicyContextStore);

export default PolicyContext;