
const uuidv4 = require('uuid/v4');

export const EventKey = {
    ShowError: 'ShowError',
    ShowSuccess: 'ShowSuccess',
    ShowWarning: 'ShowWarning',
    ShowLoader: 'ShowLoader',
    DismissLoader: 'DismissLoader',
    ShowPaymentLoader: 'ShowPaymentLoader',
    DismissPaymentLoader: 'DismissPaymentLoader',
    
    // Camera
    CAMERA_PERMISSION_DENIED: 'CAMERA_PERMISSION_DENIED',
    SHOW_SIGNATURE_POPUP: 'SHOW_SIGNATURE_POPUP',

    USER_PROFILE_SAVE: 'USER_PROFILE_SAVE',
    HULL_ITEM_CHANGED: 'HULL_ITEM_CHANGED',
    USER_PROFILE_IMAGE_UPDATED: 'USER_PROFILE_IMAGE_UPDATED',

    USER_LOGGED_IN: 'USER_LOGGED_IN',
    
    // AI
    ADDITIONAL_INSURED_FINISH: 'ADDITIONAL_INSURED_FINISH',

    // SESSION CONTEXT
    SESSION_USER_LOGOUT: 'SESSION_USER_LOGOUT',
    SHOW_COMMERCIAL_POPUP: 'SHOW_COMMERCIAL_POPUP',
    USER_ACCEPT_COMMERCIAL: 'USER_ACCEPT_COMMERCIAL',
    USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',

    // Additional Coverages 
    SHOW_INDOORS_DETAILS_POPUP: 'SHOW_INDOORS_DETAILS_POPUP',
    SHOW_WW_DETAILS_POPUP: 'SHOW_WW_DETAILS_POPUP',
    CUSTOM_WW_DETAILS_POPUP_APPROVE: 'CUSTOM_WW_DETAILS_POPUP_APPROVE',
    SHOW_SPECIAL_PRICE_POPUP: 'SHOW_SPECIAL_PRICE_POPUP',
    INDOORS_POPUP_APPROVE: 'INDOORS_POPUP_APPROVE',
    WW_DETAILS_POPUP_APPROVE: 'WW_DETAILS_POPUP_APPROVE',
    SPECIAL_POPUP_APPROVE: 'SPECIAL_POPUP_APPROVE',
    SIGN_REQUIERED_POPUP: 'SIGN_REQUIERED_POPUP',

    // FLOW 
    FLOW_NEED_LOGIN: 'FLOW_NEED_LOGIN',
    FLOW_CUSTOMIZE_FINISH: 'FLOW_CUSTOMIZE_FINISH',
    FLOW_GO_BACK: 'FLOW_GO_BACK',
    FLOW_PROFILE_FINISH: 'FLOW_PROFILE_FINISH',
    FLOW_CC_FINISH: 'FLOW_CC_FINISH',
    FLOW_SUMMARY_FINISH: 'FLOW_SUMMARY_FINISH',
    FLOW_INSURANCE_PURCHASED_SUCCESS: 'FLOW_INSURANCE_PURCHASED_SUCCESS',
    FLOW_VALIDATE_EVENT: 'FLOW_VALIDATE_EVENT',
    FLOW_CUSTOMIZE_VEHICLES_FINISH: 'FLOW_CUSTOMIZE_VEHICLES_FINISH',
    FLOW_CUSTOMIZE_OPERATORS_FINISH: 'FLOW_CUSTOMIZE_OPERATORS_FINISH',
    FLOW_COVERAGE_PLAN_PICKED: 'FLOW_COVERAGE_PLAN_PICKED',
    FLOW_ISSUANCE_FINISHED: 'FLOW_ISSUANCE_FINISHED',

    // POLICY MANAGEMENT
    OPEN_HULL_MANAGE_WINDOW: 'OPEN_HULL_MANAGE_WINDOW',
    SHOW_EXTEND_HULL_POPUP: 'SHOW_EXTEND_HULL_POPUP',
    PHYSICAL_EXTENSION_OFFER_SUCCESS: 'PHYSICAL_EXTENSION_OFFER_SUCCESS',
    LIABILITY_EXTENSION_OFFER_SUCCESS: 'PHYSICAL_EXTENSION_OFFER_SUCCESS',
    INSURED_HULL_EDIT_SUCCESS: 'INSURED_HULL_EDIT_SUCCESS',
    SCHEDULED_INSURANCE_CANCELED: 'SCHEDULED_INSURANCE_CANCELED',
    PHYSICAL_EXTENSION_PURCHASE_SUCCESS: 'PHYSICAL_EXTENSION_PURCHASE_SUCCESS',
    RECEIVED_ACTIVE_INSURANCE: 'RECEIVED_ACTIVE_INSURANCE',

    //RENEWAL 
    REFRESH_RENEWAL_OFFERS: 'REFRESH_RENEWAL_OFFERS',
    POLICY_ADDITIONAL_INSURED_CHANGED: 'POLICY_ADDITIONAL_INSURED_CHANGED',

    //DISCOUNTS 
    REDEEM_CODE_APPLIED: 'REDEEM_CODE_APPLIED',

    //CLAIMS
    CLAIM_SELECT_POLICY: 'CLAIM_SELECT_POLICY',
    CLAIM_POLICY_SELECTED: 'CLAIM_POLICY_SELECTED',
    CLAIM_INCIDENT_INFO_FINISHED: 'CLAIM_INCIDENT_INFO_FINISHED',
    CLAIM_HULL_INFO_FINISHED: 'CLAIM_HULL_INFO_FINISHED',
    CLAIM_FILES_INFO_FINISHED: 'CLAIM_FILES_INFO_FINISHED',
    CLAIM_NOTICE_INFO: 'CLAIM_NOTICE_INFO',
    
    //FLIGHT LOGS
    FLIGHT_LOGS_MORE_LOGS_POPUP_SHOW: 'FLIGHT_LOGS_MORE_LOGS_POPUP_SHOW',

    // INSURED PERSONS
    UPDATE_OPERATOR: 'UPDATE_OPERATOR',
    UPDATE_VEHICLE: 'UPDATE_VEHICLE',
    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE'
}

export const EventEmitterService = {
    events: {},
    dispatch: function(event, data) {
        console.log('EventEmitterService', event, data)
        if(!this.events[event]) return
        this.events[event].forEach(entry => entry.callback(data))
        this.events[event] = this.events[event].filter(entry => !entry.once)
    },
    once: function(event, callback) {
        if(!this.events[event]) this.events[event] = []
        let uuid = uuidv4()
        this.events[event].push({id: uuid, callback: callback, once: true})
        return uuid
    },
    subscribe: function(event, callback) {
        if(!this.events[event]) this.events[event] = []
        let uuid = uuidv4()
        this.events[event].push({id: uuid, callback: callback, once: false})
        return uuid
    },
    unsubscribe: function(event, id) {
        if(!this.events[event]) return
        this.events[event] = this.events[event].filter(entry => entry.id != id)
    }
}

